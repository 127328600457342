import AuthContext from "../../context/auth-context";
import {useContext} from "react";
import EventContext from "../../context/event-context";
import AlertContext from "../../context/alert-context";
import {Button, Grid, Typography} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {saveFile} from "../../api/event-api";
import {useParams} from "react-router-dom";
import Divider from "@mui/material/Divider";

type Props = {
    title: string;
    collection: string;
    url: string;
    handleFile: (event: any, collection: string) => void;
}
const FileRow = ({title, url, collection, handleFile}: Props) => {

    return <>
        <Grid item container justifyContent={"space-between"} mt={3}>
            <Grid item xs={4}>
                <Typography>{title}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Button
                    fullWidth
                    sx={{color: "white !important"}}
                    variant="contained"
                    component="label"
                    startIcon={<FileUploadIcon/>}
                >
                    Załaduj plik
                    <input
                        type="file"
                        hidden
                        onChange={(event) => handleFile(event.target, collection)}
                    />
                </Button>
            </Grid>
            <Grid item container xs={4} justifyContent={'end'}>
                {url != '' ?
                    <Button component={'a'} target="_blank" variant={'contained'} href={url}
                            color={'success'} startIcon={<VisibilityIcon/>}>Podgląd</Button>
                    :
                    <Typography sx={{fontStyle: 'italic', textAlign: "end"}}>Brak pliku</Typography>
                }
            </Grid>
        </Grid>
        <Divider/>
    </>
}
const EventFilesPanel = () => {
    const {user} = useContext(AuthContext);
    const ctx = useContext(EventContext);
    const {setAlert} = useContext(AlertContext);
    const {slug} = useParams();

    const handleCapture = (target: any, collection: string) => {
        const fileReader = new FileReader();
        const name = target.accept.includes('image') ? 'images' : 'videos';
        saveFile(user?.token,
            slug ? slug : '',
            {file: target.files[0], collection},
            (event) => {
                ctx.setEvent(event)
                setAlert({title: 'Dodano poprawnie plik', color: 'success'})
            },
            (error) => {
                console.log(error)
                setAlert({title: error})
            }
        )
    };

    const files = [
        {
            title: 'Grafika panelu hostess',
            url: ctx.event?.panelImageUrl ? ctx.event?.panelImageUrl : '',
            collection: 'register-panel',
        },
        {
            title: 'Regulamin',
            url: ctx.event?.statuteUrl ? ctx.event?.statuteUrl : '',
            collection: 'statute',
        },
        {
            title: 'Animacja przegranej',
            url: ctx.event?.loseAnimation ? ctx.event?.loseAnimation : '',
            collection: 'lose-animation',
        },
        {
            title: 'Animacja wygranej',
            url: ctx.event?.winAnimation ? ctx.event?.winAnimation : '',
            collection: 'win-animation',
        },
        {
            title: 'Główny ekran loteriomatu',
            url: ctx.event?.lotteryPanel ? ctx.event?.lotteryPanel : '',
            collection: 'lottery-panel',
        }
    ]

    return <Grid
        container
        mt={2}
        rowGap={2}
        alignItems={"start"}
        flexDirection={"column"}
    >
        {files.map((f: any, i: number) =>
            <FileRow title={f.title} collection={f.collection} url={f.url} handleFile={handleCapture}/>)}

    </Grid>
}
export default EventFilesPanel;