/* eslint-disable react/prop-types */
import MDBox from "components/parts/MDBox";
import MDTypography from "components/parts/MDTypography";

export default function data(daily) {

    const Date = ({date}) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
                {date}
            </MDTypography>
        </MDBox>
    );

    return {
        columns: [
            {Header: "Data", accessor: "date",  align: "left"},
            {Header: "Liczba uczestników", accessor: "participantsNumber", width: "15%", align: "center"},
            {Header: "Liczba rejestracji", accessor: "registrationNumber", width: "15%", align: "center"},
            {Header: "Liczba paragonów", accessor: "receiptsNumber", width: "15%", align: "center"},
            {Header: "Łączna wartość", accessor: "totalValue", width: "15%",align: "center"},
            {Header: "Średnia wartość", accessor: "averageValue", width: "15%",align: "center"},
            {Header: "Wygrane", accessor: "won", width: "10%",align: "center"},
            {Header: "Odebrane nagrody", accessor: "collected", width: "10%",align: "center"},
        ],

        rows: daily ? daily.map(daily => {
            return {
                date: <Date date={daily.date}/>,
                participantsNumber: (
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        {daily.participantsNumber}
                    </MDTypography>
                ),
                registrationNumber: (
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        {daily.couponsNumber}
                    </MDTypography>
                ),
                receiptsNumber: (
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        {daily.receiptsNumber}
                    </MDTypography>
                ),
                totalValue: (
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        {Math.round((Number(daily.receiptsSum) + Number.EPSILON) * 100) / 100 + " zł"}
                    </MDTypography>
                ),
                averageValue: (
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        {Math.round((Number(daily.receiptsAvg) + Number.EPSILON) * 100) / 100 + " zł"}
                    </MDTypography>
                ),
                won: (
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        {daily.won}
                    </MDTypography>
                ),
                collected: (
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        {daily.collected}
                    </MDTypography>
                ),
            }
        },) : []
    };
}
