import axios from "./api-client";
import {Event} from "model/Event";
import Settings from "../model/Settings";

// import {Gallery} from "../model/Gallery";
//
// export const getGalleries = (handleData: (galleries: Gallery []) => void, onError?: (error: string) => void) => {
//     const url = process.env.MIX_API_URL ? process.env.MIX_API_URL + '/galleries' : "";
//     const config = {
//         headers: {
//             "Accept-Content": "application/json"
//         }
//     }
//     axios.get(url, config).then((response) =>{
//         if (response.status == 200) {
//             const galleries = response.data.map(item => new Gallery(item.id, item.slug, item.name));
//             handleData(galleries);
//         } else {
//             onError && onError(JSON.stringify(response.data.message));
//         }
//     }).catch((error) => {
//         console.error(error.response.data.message? error.response.data.message : error);
//         onError && onError(error.response.data.message ? error.response.data.message : "Błąd serwera!");
//     })
// }

export const persistEvent = (token: string | undefined, data: any, handleData: (event: Event) => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/api/events' : '';
    const config = {
        headers: {
            'Accept-Content': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    axios.post(url, data, config).then((response) => {
        if (response.status == 200) {
            const event = Event.fromApiResponse(response.data)
            handleData(event);
        } else {
            onError && onError(JSON.stringify(response.data.message));
        }
    }).catch((error) => {
        console.error(error.response.data.message ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : 'Błąd serwera!');
    })
}

export const getEventBySlug = (token: string | undefined, slug: string, handleData: (event: Event) => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/api/events/' + slug : '';
    const config = {
        headers: {
            'Accept-Content': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    axios.get(url, config).then((response) => {
        if (response.status == 200) {
            const event = Event.fromApiResponse(response.data)
            handleData(event);
        } else {
            onError && onError(JSON.stringify(response.data.message));
        }
    }).catch((error) => {
        console.error(error.response.data.message ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : 'Błąd serwera!');
    })
}

export const persistSettings = (token: string | undefined, slug: string, data: Settings, handleData: (event: Event) => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/api/events/' + slug + '/settings' : '';
    const config = {
        headers: {
            'Accept-Content': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    axios.post(url, data, config).then((response) => {
        if (response.status == 200) {
            const event = Event.fromApiResponse(response.data)
            handleData(event);
        } else {
            onError && onError(JSON.stringify(response.data.message));
        }
    }).catch((error) => {
        console.error(error.response.data.message ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : 'Błąd serwera!');
    })
}

export const saveFile = (token: string | undefined, slug: string, data: any, handleData: (event: Event) => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/api/events/' + slug + '/files' : '';
    const config = {
        headers: {
            'Accept-Content': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    let form = new FormData()
    form.append('file', data.file)
    form.append('collection', data.collection)

    axios.post(url, form, config).then((response) => {
        if (response.status == 200) {
            const event = Event.fromApiResponse(response.data)
            handleData && handleData(event);
        } else {
            onError && onError(JSON.stringify(response.data.message));
        }
    }).catch((error) => {
        console.error(error.response.data.message ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : 'Błąd serwera!');
    })
}

export const persistShops = (token: string | undefined, slug: string, shops: string[], handleData: (event: Event) => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/api/events/' + slug + '/shops' : '';
    const config = {
        headers: {
            'Accept-Content': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    axios.post(url, {shops}, config).then((response) => {
        if (response.status == 200) {
            const event = Event.fromApiResponse(response.data)
            handleData(event);
        } else {
    onError && onError(JSON.stringify(response.data.message));
}
}).catch((error) => {
    console.error(error.response.data.message ? error.response.data.message : error);
    onError && onError(error.response.data.message ? error.response.data.message : 'Błąd serwera!');
})
}

export const getResetKey = (token: string | undefined, slug: string, handleData: (key: string) => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/api/events/' + slug + '/reset-key' : '';
    const config = {
        headers: {
            'Accept-Content': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    axios.get(url, config).then((response) => {
        if (response.status == 200) {
            handleData(response.data);
        } else {
            onError && onError(JSON.stringify(response.data.message));
        }
    }).catch((error) => {
        console.error(error.response.data.message ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : 'Błąd serwera!');
    })
}

export const resetEvent = (token: string | undefined, slug: string, key: string, handleSuccess: () => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/api/events/' + slug + '/reset' : '';
    const config = {
        headers: {
            'Accept-Content': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    axios.post(url, {key}, config).then((response) => {
        if (response.status == 200) {
            handleSuccess()
        } else {
            onError && onError(JSON.stringify(response.data.message));
        }
    }).catch((error) => {
        console.error(error.response.data.message ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : 'Błąd serwera!');
    })
}

