export const EMAIL_ID_FIELD = 'email'
export const PHONE_ID_FIELD = 'phone'
export const LOYALTY_CARD_NUMBER_ID_FIELD = 'loyalty_card_number'

class Settings {
    identifier?: string;
    firstNameRequired?: boolean;
    lastNameRequired?: boolean;
    phoneRequired?: boolean;
    emailRequired?: boolean;
    zipCodeRequired?: boolean;
    loyaltyCardNumberRequired?: boolean;
    eventType?: string;
    maxPrizePerDay?: number;
    maxPrizeTotal?: number;
    maxChances?: number;
    maxReceipts?: number;
    chanceStep?: number;
    agreements?: string[];
    smsPrefix?: string;
    smsSuffix?: string;
    smsSender?: string;
    animationFrom?: number;
    animationTo?: number;
    animationColor?: string;
    extraChances?: boolean;
    maxExtraChancesPerRegistration?: number;
    maxExtraChancesTotal?: number;

    constructor(identifier: string, firstNameRequired: boolean, lastNameRequired: boolean, phoneRequired: boolean, emailRequired: boolean, zipCodeRequired: boolean, loyaltyCardNumberRequired: boolean, eventType: string, maxPrizePerDay: number, maxPrizeTotal: number, maxChances: number, maxReceipts: number, chanceStep: number, agreements: string[], smsPrefix: string, smsSuffix: string, smsSender: string, animationFrom: number, animationTo: number, animationColor: string, extraChances: boolean, maxExtraChancesPerRegistration: number, maxExtraChancesTotal: number) {
        this.identifier = identifier;
        this.firstNameRequired = firstNameRequired;
        this.lastNameRequired = lastNameRequired;
        this.phoneRequired = phoneRequired;
        this.emailRequired = emailRequired;
        this.zipCodeRequired = zipCodeRequired;
        this.loyaltyCardNumberRequired = loyaltyCardNumberRequired;
        this.eventType = eventType;
        this.maxPrizePerDay = maxPrizePerDay;
        this.maxPrizeTotal = maxPrizeTotal;
        this.maxChances = maxChances;
        this.maxReceipts = maxReceipts;
        this.chanceStep = chanceStep;
        this.agreements = agreements;
        this.smsPrefix = smsPrefix;
        this.smsSuffix = smsSuffix;
        this.smsSender = smsSender;
        this.animationFrom = animationFrom;
        this.animationTo = animationTo;
        this.animationColor = animationColor;
        this.extraChances = extraChances;
        this.maxExtraChancesPerRegistration = maxExtraChancesPerRegistration;
        this.maxExtraChancesTotal = maxExtraChancesTotal;
    }

    public static fromApiResponse(data: any) {
        return new Settings(
            data?.identifier ? data.identifier : '',
            data?.first_name_required == 1,
            data?.last_name_required == 1,
            data?.phone_required == 1,
            data?.email_required == 1,
            data?.zip_code_required == 1,
            data?.loyalty_card_number_required == 1,
            data?.event_type ? data.event_type : '',
            data?.max_prize_per_day ? data?.max_prize_per_day : 0,
            data?.max_prize_total ? data?.max_prize_total : 0,
            data?.max_chances ? data?.max_chances : 0,
            data?.max_receipts ? data?.max_receipts : 0,
            data?.chance_step ? data?.chance_step : 0,
            data?.agreements ? data.agreements.map((a: any) => a.content) : [],
            data?.sms_prefix,
            data?.sms_suffix,
            data?.sms_sender,
            data?.animation_from,
            data?.animation_to,
            data?.animation_color,
            data?.extra_chances,
            data?.max_extra_chances_per_registration ? data?.max_extra_chances_per_registration : 0,
            data?.max_extra_chances_total ? data?.max_extra_chances_total : 0,
        )
    }
}

export default Settings;