import {useContext, useState} from "react";
import AlertContext from "../../context/alert-context";
import EventContext from "../../context/event-context";
import AuthContext from "../../context/auth-context";
import {useParams} from "react-router-dom";
import {
    Button,
    CircularProgress,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {CSVReader} from "react-papaparse";
import Typography from "@mui/material/Typography";
import {persistShops} from "../../api/event-api";
import {Event} from "../../model/Event";
import { Shop } from "model/Shop";
import LinearProgress from "@mui/material/LinearProgress";

const EventShopsPanel = () => {
    const {setAlert} = useContext(AlertContext);
    const {event, setShops, setEvent} = useContext(EventContext);
    const {user} = useContext(AuthContext);
    const [sending, setSending] = useState(false);
    const {slug} = useParams();

    const handleOnDrop = (data: any, file: any) => {
        let shops = []
        try {
            shops = data.filter((d: any) => {
                return d.data[0] !== '';
            }).map((d: any) => {
                return d.data[0].replace(/[/. ]/g, '-')
            })
        } catch (error) {
            console.log(error)
            setAlert({title: 'Błąd przy wczytywaniu pliku. Sprawdź format danych', color: "error"})
            return;
        }
        // @ts-ignore
        shops = [...new Set(shops)];
        shops = shops.map((s: string) => {
            return {name: s}
        })
        setShops(shops);
    }

    const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
        setAlert({title: 'Błąd czytnika csv. Sprawdź format danych', color: "error"})
    }

    const handleOnRemoveFile = () => {
    }

    const saveShops = () => {
        if (event?.shops && event.shops.length > 0) {
            const shopNames = event.shops.map((s: Shop) => s.name)
            setSending(true);
            persistShops(user?.token,
                slug ? slug : '',
                shopNames,
                (event: Event) => {
                    setEvent(event)
                    setSending(false);
                    setAlert({title: 'Sklepy został poprawnie dodane.', color: 'success'})
                },
                (error: any) => {
                    console.log(error)
                    setSending(false);
                    setAlert({title: error})
                })

        } else {
            setAlert({title: "Lista sklepów jest pusta", color: 'warning'})
        }
    }


    return <Grid
        container
        mt={2}
        rowGap={2}
        alignItems={"start"}
        flexDirection={"column"}
    >
        <Grid item container justifyContent={'space-between'}>
            <Grid item container xs={5}>
                <CSVReader
                    onDrop={handleOnDrop}
                    onError={handleOnError}
                    addRemoveButton
                    removeButtonColor={"red"}
                    onRemoveFile={handleOnRemoveFile}
                    config={{
                        encoding: "UTF-8"
                    }}
                >
                    <span>Import sklepów</span>
                </CSVReader>
            </Grid>
            <Grid item container xs={5}>
                <Grid item container flexDirection={'column'} gap={3}>
                    <Typography sx={{fontSize: "14px"}}>Plik z pojedynczą kolumną bez nagłówka</Typography>
                    <Typography component={'a'} href={'/shops-example.csv'} sx={{fontSize: "14px"}}>Przykładowy
                        plik</Typography>
                    {event?.shops && event?.shops.length > 0 &&
                        <Button variant={"contained"} sx={{color: "white !important"}} disabled={sending} onClick={saveShops}>
                            {sending ?  <CircularProgress color={'secondary'}/> : "Zapisz zmiany"}
                        </Button>
                    }
                </Grid>
            </Grid>
        </Grid>
        {event?.shops && event?.shops.length > 0 && <>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nazwa sklepu</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {event.shops.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>}
    </Grid>
}

export default EventShopsPanel;