import axios from "./api-client";
import {Statistic} from "../model/Statistic";

export const getStatistics = (token: string | undefined, eventSlug: string, handleData: (statistic: Statistic) => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/api/events/' + eventSlug + '/statistics' : "";
    const config = {
        headers: {
            "Accept-Content": "application/json",
            "Authorization" : "Bearer " + token
        }
    }
    axios.get(url, config).then((response) =>{
        if (response.status == 200) {
            const statistic = Statistic.fromApiResponse(response.data);
            handleData(statistic);
        } else {
            onError && onError(JSON.stringify(response.data.message));
        }
    }).catch((error) => {
        console.error(error.response.data ? error.response.data.message : error);
        onError && onError(error.response.data ? error.response.data.message : "Błąd serwera!");
    })
}
