import React, { createContext, FC, useState} from "react";

export type AlertContextType = {
    color: string;
    icon: string;
    title: string;
    dateTime: string;
    open: boolean;
    content: string;
    close: () => void;
    bgWhite: false;
    xl: boolean;
    setAlert: ({}:{title: string, color?: string, xl?: boolean}) => void;
}

const InitialAlertContext: AlertContextType = {
    color: "error",
    icon: "",
    title: "",
    dateTime: "",
    open: false,
    content: "",
    close: () => {},
    bgWhite: false,
    xl: false,
    setAlert: ({title: string, color, xl}) => {},
}

const AlertContext = createContext(InitialAlertContext);

export const AlertContextProvider: FC = ({children}) => {
    const [title, setTitle] = useState("");
    const [xl, setXl] = useState(false);
    const [color, setColor] = useState("error");

    const setAlert = ({title, color, xl}: {title: string, color?: string, xl?: boolean}) => {
        setTitle(title);
        if(color) {
            setColor(color);
        } else {
            setColor("error");
        }
        if(xl) {
            setXl(xl);
        }
        const timeout = setTimeout(()=> {
            setTitle("");
        }, 5000);

        return (() => {clearTimeout(timeout)})
    }

    const contextValue: AlertContextType = {
        ...InitialAlertContext,
        color: color,
        title: title,
        xl: xl,
        setAlert: setAlert,
        close: () => {setTitle("")},
        open: title !="",
    }

    return <AlertContext.Provider value={contextValue}>{children}</AlertContext.Provider>
}

export default AlertContext;
