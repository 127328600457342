import axios from "./api-client";
import {User} from "../model/User";

const loginUrl = process.env.REACT_APP_API_URL + "/api/login";
const csrfUrl = process.env.REACT_APP_API_URL + "/sanctum/csrf-cookie";
const refreshUrl = process.env.REACT_APP_API_URL + "/api/refresh";
const logoutUrl = process.env.REACT_APP_API_URL + "/api/logout";

export const login = (data: { email: string, password: string }, handleData: (user: User) => void, onError?: (message: string) => void) => {

    axios.get(csrfUrl).then((res: any) => {
        axios.post(loginUrl, data)
            .then((response) => {
                if (response.status == 200) {
                    const user = User.fromApiResponse(response.data);
                    handleData(user);
                } else {
                    onError && onError(JSON.stringify(response.data));
                }
            })
            .catch((error) => {
                console.error(error.response ? error.response : error);
                onError && onError("Niepoprawne dane logowania");
            })
    })
        .catch((error) => {
            console.error(error.response ? error.response : error);
            onError && onError("Błąd ciasteczek");
        })
}

export const refreshToken = (token: string | undefined, handleData: (user: User) => void, onError?: (message: string) => void) => {
    const config = {
        headers: {
            'Accept-Content': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    axios.get(refreshUrl, config)
        .then((response) => {
            if (response.status == 200) {
                const user = User.fromApiResponse(response.data);
                handleData(user);
            } else {
                onError && onError(JSON.stringify(response.data));
            }
        })
        .catch((error) => {
            console.error(error.response ? error.response : error);
            onError && onError("Niepoprawne dane logowania");
        })
}

export const logout = (onSuccess?: () => void, onError?: (message: string) => void) => {
    axios.post(logoutUrl, {}, {})
        .then((response) => {
            onSuccess && onSuccess();
        })
        .catch((error) => {
            console.error(error.message ? error.message : error);
            onError && onError(error.message ? error.message : "Błąd serwera!");
        });
}
