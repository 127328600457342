export class Shop {
    id?: string;
    name: string;

    private constructor(name: string, id?: string) {
        this.id = id;
        this.name = name;
    }

    public static fromApiResponse(data: any) {
        return new Shop(
            data?.name ? data?.name : '',
            data?.id ? data.id : ''
        )
    }


}
