import React, {useContext, useEffect, useState} from "react";
import {FC} from "react";
import Card from "@mui/material/Card";
import MDBox from "components/parts/MDBox";
import MDTypography from "components/parts/MDTypography";
import DataTable from "components/Tables/DataTable";
import data from "./data";
import StatisticsContext from "../../../../context/statistics-context";

const DailyStats = () => {
    const {statistics:{dailyStatistics}} = useContext(StatisticsContext);
    const dailyData = data(dailyStatistics);

    return <Card>
        <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
        >
            <MDTypography variant="h6" color="white">
                Statystyki dzienne
            </MDTypography>
        </MDBox>
        <MDBox pt={3}>
            <DataTable
                table={dailyData}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
            />
        </MDBox>
    </Card>
}

export default DailyStats;
