import React, { createContext, FC, useState} from "react";
import {DateStatistics, PrizesStock, Statistic} from "../model/Statistic";

export type StatisticsContextType = {
    statistics: Statistic,
    setStatistics: (statistic: Statistic) => void,
}

const InitialStatisticsContext: StatisticsContextType = {
    statistics: {} as Statistic,
    setStatistics: (statistic: Statistic) => {},
}

const StatisticsContext = createContext(InitialStatisticsContext);

export const StatisticsContextProvider: FC = ({children}) => {
    const [statistics, setStats] = useState<Statistic>({} as Statistic);

    const setStatistics = (statistics: Statistic) => setStats(statistics);

    const contextValue: StatisticsContextType = {
        ...InitialStatisticsContext,
        statistics: statistics,
        setStatistics: setStatistics
    }

    return <StatisticsContext.Provider value={contextValue}>{children}</StatisticsContext.Provider>
}

export default StatisticsContext;
