import Card from "@mui/material/Card";
import MDBox from "components/parts/MDBox";
import MDTypography from "components/parts/MDTypography";
import DataTable from "components/Tables/DataTable";
import data from "pages/dashboard/components/ShopsData/data";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useEffect, useState} from "react";

function ShopsData({shopsData}) {
    const {columns: cols, rows: rs} = data(shopsData);
    const [columns, setColumns] = useState(cols);
    const [rows, setRows] = useState(rs);
    const [sort, setSort] = useState("receiptNumberDesc");

    useEffect(() => {
        switch (sort) {
            case "receiptNumberAsc": {
                shopsData && shopsData.sort((a, b) => {
                    return a.receiptsNumber - b.receiptsNumber || a.totalValue - b.totalValue;
                })
                break;
            }
            case "receiptValueDesc": {
                shopsData && shopsData.sort((a, b) => {
                    return b.totalValue - a.totalValue || b.receiptsNumber - a.receiptsNumber;
                })
                break;
            }
            case "receiptValueAsc": {
                shopsData && shopsData.sort((a, b) => {
                    return a.totalValue - b.totalValue || a.receiptsNumber - b.receiptsNumber;
                })
                break;
            }
            default: {
                shopsData && shopsData.sort((a, b) => {
                    return b.receiptsNumber - a.receiptsNumber || b.totalValue - a.totalValue;
                })
            }
        }
        const {columns, rows} = data(shopsData);
        setColumns(columns);
        setRows(rows);
    }, [sort, shopsData]);

    return (
        <Card>
            <MDBox display="flex" alignItems="stretch" justifyContent={"space-between"} p={3}>
                <MDBox display="flex" alignItems="center">
                    <MDTypography variant="h6" gutterBottom>
                        Dane sklepów
                    </MDTypography>
                </MDBox>
                <FormControl sx={{minHeight: "40px"}}>
                    <InputLabel id="sorting-label">Sortuj wg</InputLabel>
                    <Select
                        labelId="sorting-label"
                        id="sorting-id"
                        value={sort}
                        label="Sortowanie"
                        onChange={(event) => setSort(event.target.value)}
                        sx={{minHeight: "40px", minWidth: "220px"}}
                    >
                        <MenuItem value={"receiptNumberAsc"}>Liczba paragonów rosnąco</MenuItem>
                        <MenuItem value={"receiptNumberDesc"}>Liczba paragonów malejąco</MenuItem>
                        <MenuItem value={"receiptValueAsc"}>Łączna wartość rosnąco</MenuItem>
                        <MenuItem value={"receiptValueDesc"}>Łączna wartość malejąco</MenuItem>
                    </Select>
                </FormControl>
            </MDBox>
            <MDBox>
                <DataTable
                    table={{columns, rows}}
                    showTotalEntries={true}
                    isSorted={false}
                    noEndBorder
                    entriesPerPage={10}
                />
            </MDBox>
        </Card>
    );
}

export default ShopsData;
