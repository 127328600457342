import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import "core-js/stable";
import "regenerator-runtime/runtime";
import {MaterialUIControllerProvider} from "./context/material-ui-context";
import App from "./app";
import './index.css';
import {AuthContextProvider} from "./context/auth-context";
import {AlertContextProvider} from "./context/alert-context";
import {StatisticsContextProvider} from "./context/statistics-context";
import {EventContextProvider} from "./context/event-context";

if (document.getElementById('root')) {
    ReactDOM.render(
        <BrowserRouter>
            <AlertContextProvider>
                <AuthContextProvider>
                    <StatisticsContextProvider>
                        <MaterialUIControllerProvider>
                            <EventContextProvider>
                                <App/>
                            </EventContextProvider>
                        </MaterialUIControllerProvider>
                    </StatisticsContextProvider>
                </AuthContextProvider>
            </AlertContextProvider>
        </BrowserRouter>,
        document.getElementById("root")
    );
}



