import axios from "./api-client";
import {Prize} from "../model/Prize";

export const getAllPrizes = (token: string| undefined, slug: string, handleData?: (prizes: Prize[]) => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/api/events/${slug}/prizes` : '';
    const config = {
        headers: {
            'Accept-Content': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    axios.get(url, config).then((response) =>{
        if (response.status == 200) {
            const prizes = response.data.map((d: any) => Prize.fromApiResponse(d))
            handleData && handleData(prizes);
        } else {
            onError && onError(JSON.stringify(response.data.message));
        }
    }).catch((error) => {
        console.error(error.response && error.response.data.message ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : "Błąd serwera!");
    })
}

export const deletePrize = (token: string| undefined, eventSlug: string, prizeId: number, handleSuccess: () => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/api/events/${eventSlug}/prizes/${prizeId}` : '';
    const config = {
        headers: {
            'Accept-Content': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    axios.delete(url, config).then((response) =>{
        if (response.status == 200) {
            handleSuccess()
        } else {
            onError && onError(JSON.stringify(response.data.message));
        }
    }).catch((error) => {
        console.error(error.response && error.response.data.message ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : "Błąd serwera!");
    })
}

export const deleteAllPrizes = (token: string| undefined, eventSlug: string, handleSuccess: (message: string) => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/api/events/${eventSlug}/prizes` : '';
    const config = {
        headers: {
            'Accept-Content': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    axios.delete(url, config).then((response) =>{
        if (response.status == 200) {
            handleSuccess(response.data.message)
        } else {
            onError && onError(JSON.stringify(response.data.message));
        }
    }).catch((error) => {
        console.error(error.response && error.response.data.message ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : "Błąd serwera!");
    })
}

export const editPrizeName = (token: string| undefined, eventSlug: string, prizeId: number, prizeName: string, handleSuccess: (prize: Prize) => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/api/events/${eventSlug}/prizes/${prizeId}` : '';
    const config = {
        headers: {
            'Accept-Content': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    axios.patch(url, {name: prizeName}, config).then((response) =>{
        if (response.status == 200) {
            handleSuccess(Prize.fromApiResponse(response.data))
        } else {
            onError && onError(JSON.stringify(response.data.message));
        }
    }).catch((error) => {
        console.error(error.response && error.response.data.message ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : "Błąd serwera!");
    })
}

export const uploadPrizes = (token: string| undefined, eventSlug: string, prizes: {name: string, winningDate: string}[], handleSuccess: (prizes: Prize[]) => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/api/events/${eventSlug}/prizes/upload` : '';
    const config = {
        headers: {
            'Accept-Content': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    axios.post(url, prizes, config).then((response) =>{
        if (response.status == 200) {
            handleSuccess(response.data.map((d: any) => Prize.fromApiResponse(d)));
        } else {
            onError && onError(JSON.stringify(response.data.message));
        }
    }).catch((error) => {
        console.error(error.response && error.response.data.message ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : "Błąd serwera!");
    })
}

export const getReports = (token: string| undefined, eventSlug: string, prizes: {name: string, winningDate: string}[], onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/api/events/${eventSlug}/reports` : '';
    const config = {
        responseType: 'blob',
        headers: {
            'Accept-Content': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    // @ts-ignore
    axios.get(url, config).then((response) =>{
        if (response.status == 200) {
            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'protokoly-' + eventSlug + '.zip');
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        } else {
            onError && onError(JSON.stringify(response.data.message));
        }
    }).catch((error) => {
        console.error(error.response && error.response.data.message ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : "Błąd serwera!");
    })
}
