import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    CircularProgress,
    Modal,
    Typography
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {useContext, useState} from "react";
import AlertContext from "../context/alert-context";
import AuthContext from "../context/auth-context";
import {persistEvent} from "api/event-api";
import {Event} from "model/Event";

type Props = {
    open: boolean;
    onClose: () => void;
}

type Field = {
    value: string;
    error?: string
}
const EmptyField = {
    value: '',
}
const NAME_ERROR = "Niepoprawna nazwa wydarzenia (min.5 znaków)"
const GALLERY_ERROR = "Niepoprawna nazwa galerii handlowej (min.5 znaków)"
const STREET_ERROR = "Niepoprawna nazwa ulicy (min.5 znaków)"
const NUMBER_ERROR = "Niepoprawna numer budynku / lokalu (min.1 znaków)"
const ZIP_CODE_ERROR = "Niepoprawny kod pocztowy (np.: 88-111)"
const CITY_ERROR = "Niepoprawna nazwa miasta (min.3 znaków)"
const NewEventModal = ({open, onClose}: Props) => {
    const {setAlert} = useContext(AlertContext);
    const [sending, setSending] = useState(false);
    const {user, refresh} = useContext(AuthContext);
    const [validated, setValidated] = useState(false);
    const [name, setName] = useState<Field>({...EmptyField, error: NAME_ERROR});
    const [gallery, setGallery] = useState<Field>({...EmptyField, error: GALLERY_ERROR});
    const [zipCode, setZipCode] = useState<Field>({...EmptyField, error: ZIP_CODE_ERROR});
    const [street, setStreet] = useState<Field>({...EmptyField, error: STREET_ERROR});
    const [number, setNumber] = useState<Field>({...EmptyField, error: NUMBER_ERROR});
    const [city, setCity] = useState<Field>({...EmptyField, error: CITY_ERROR});

    const nameChangeHandler = (value: string) => {
        if (value.length > 5) {
            setName({value})
        } else {
            setName({value, error: NAME_ERROR})
        }
    }

    const galleryChangeHandler = (val: string) => {
        if (val.length > 5) {
            setGallery({value: val})
        } else {
            setGallery({value: val, error: GALLERY_ERROR})
        }
    }

    const streetChangeHandler = (value: string) => {
        if (value.length > 5) {
            setStreet({value})
        } else {
            setStreet({value, error: STREET_ERROR})
        }
    }

    const numberChangeHandler = (val: string) => {
        const value = val.trim()
        if (value.length > 0) {
            setNumber({value})
        } else {
            setNumber({value, error: NUMBER_ERROR})
        }
    }

    const zipCodeChangeHandler = (val: string) => {
        const value = val.trim()
        const reg = new RegExp('^[0-9]{2}-[0-9]{3}$')
        if (reg.test(value)) {
            setZipCode({value})
        } else {
            setZipCode({value, error: NUMBER_ERROR})
        }
    }

    const cityChangeHandler = (value: string) => {
        if (value.length > 2) {
            setCity({value})
        } else {
            setCity({value, error: NUMBER_ERROR})
        }
    }

    const saveEvent = () => {
        setValidated(true);
        if (
            name.error ||
            gallery.error ||
            street.error ||
            number.error ||
            zipCode.error ||
            city.error
        ) {
            setAlert({title: "Uzupełnij poprawnie formularz"})
        } else {
            const data = {
                zip_code: zipCode.value,
                name: name.value,
                gallery: gallery.value,
                street: street.value,
                number: number.value,
                city: city.value
            }
            setSending(true);
            persistEvent(user?.token,
                data,
                (event: Event) => {
                    refresh()
                    onClose()
                    setSending(false);
                    setAlert({title: "Nowe wydarzenie zostało utworzone. Uzupełnij dane w zakładce 'Ustawienia'", color: "success"});
                },
                (error) => {
                    console.error(error);
                    setSending(false);
                    setAlert({title: error});
                }
            )
        }
    }

    return <Modal
        open={open}
        onClose={onClose}
        sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center"
        }}
    >
        <Card
            sx={{
                maxWidth: "50%",
                maxHeight: "80%",
                position: "absolute",
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                overflow: 'auto'
            }}
        >
            <CardHeader
                title={"Nowe wydarzenie"}
            />
            <CardContent>
                <TextField
                    fullWidth
                    placeholder={"Nazwa wydarzenia"}
                    value={name.value}
                    onChange={({target: {value}}) => nameChangeHandler(value)}
                    error={!!name.error && validated}
                    helperText={validated && name.error}
                />
                <Typography variant={"subtitle2"} mt={2}>Galeria handlowa</Typography>
                <TextField
                    fullWidth
                    placeholder={"Nazwa galerii handlowej"}
                    value={gallery.value}
                    onChange={({target: {value}}) => galleryChangeHandler(value)}
                    error={!!gallery.error && validated}
                    helperText={validated && gallery.error}
                    sx={{
                        my: 1
                    }}
                />
                <TextField
                    fullWidth
                    placeholder={"Ulica"}
                    value={street.value}
                    onChange={({target: {value}}) => streetChangeHandler(value)}
                    error={!!street.error && validated}
                    helperText={validated && street.error}
                    sx={{
                        my: 1
                    }}
                />
                <TextField
                    fullWidth
                    placeholder={"Numer domu / lokalu"}
                    value={number.value}
                    onChange={({target: {value}}) => numberChangeHandler(value)}
                    error={!!number.error && validated}
                    helperText={validated && number.error}
                    sx={{
                        my: 1
                    }}
                />
                <TextField
                    fullWidth
                    placeholder={"Kod pocztowy"}
                    value={zipCode.value}
                    onChange={({target: {value}}) => zipCodeChangeHandler(value)}
                    error={!!zipCode.error && validated}
                    helperText={validated && zipCode.error}
                    sx={{
                        my: 1
                    }}
                />
                <TextField
                    fullWidth
                    placeholder={"Miasto"}
                    value={city.value}
                    onChange={({target: {value}}) => cityChangeHandler(value)}
                    error={!!city.error && validated}
                    helperText={validated && city.error}
                    sx={{
                        my: 1
                    }}
                />
                <Button
                    fullWidth
                    variant={"contained"}
                    onClick={saveEvent}
                    disabled={sending}
                    sx={{
                        mt: 3,
                        color: "white !important"
                    }}
                >
                    {!sending ? "Zapisz" : <CircularProgress/>}
                </Button>
            </CardContent>
        </Card>
    </Modal>
}

export default NewEventModal;