/* eslint-disable react/prop-types */

// Soft UI Dashboard React components
import MDBox from "components/parts/MDBox";
import MDTypography from "components/parts/MDTypography";
import {Link} from "react-router-dom";

import {Icon} from "@mui/material";

export default function data(participants, slug) {
    const Participant = ({firstName, lastName}) => {
            let fullName = "-";
            if (!!firstName || !!lastName) {
                fullName = (firstName ? firstName : '') + " " + (lastName ? lastName : '')
            }
            return <MDBox display="flex" alignItems="center" lineHeight={1}>
                <Icon size={"large"}>account_circle</Icon>
                <MDBox ml={2} lineHeight={1}>
                    <MDTypography display="block" variant="button" fontWeight="medium">
                        {fullName}
                    </MDTypography>
                </MDBox>
            </MDBox>
        }
    ;

    const Data = ({title}) => (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {title}
            </MDTypography>
        </MDBox>
    );

    return {
        columns: [
            {Header: "Imię i nazwisko", accessor: "contact", align: "left", sortType: "custom" },
            {Header: "Telefon", accessor: "phone", align: "left", sortType: "custom" },
            {Header: "Email", accessor: "email", align: "left", sortType: "custom" },
            {Header: "Zarejstrowany", accessor: "registered", align: "center", sortType: "custom" },
            {Header: "Łączna kwota paragonów", accessor: "receipts", align: "center", sortType: "custom" },
            {Header: "Więcej", accessor: "action", align: "center", sortType: "custom" },
        ],

        rows: participants.map(pt => {
            return {
                contact: <Participant firstName={pt.firstName} lastName={pt.lastName}/>,
                phone: <Data title={pt.phone} description={null}/>,
                email: <Data title={pt.email}/>,
                registered: (
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        {pt.created.toLocaleString()}
                    </MDTypography>
                ),
                receipts: (
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        {pt.receipts.map(a => a.amount).reduce((a, b) => a + b)}
                    </MDTypography>
                ),
                action: (
                    <Link to={`/${slug}/uczestnicy/${pt.id}`}>
                        <Icon>more_horiz</Icon>
                    </Link>
                ),
            }
        })
    };
}
