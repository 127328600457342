/* eslint-disable react/prop-types */
import MDBox from "components/parts/MDBox";
import MDTypography from "components/parts/MDTypography";
import MDProgress from "components/parts/MDProgress";

<MDProgress value="75" variant="gradient" label />

export default function data(stats) {

    const calcProgress = (num, total) => {
        return Math.round(num / total * 100)
    }
    const Title = ({text}) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
                {text}
            </MDTypography>
        </MDBox>
    );

    return {
        columns: [
            {Header: "Nagroda", accessor: "name",  align: "left"},
            {Header: "", accessor: "chart", width: "20%", align: "right"},
            {Header: "Pozostało", accessor: "number", width: "20%", align: "center"},
            {Header: "Łączna ilość", accessor: "totalNumber", width: "20%", align: "center"},
        ],

        rows: stats ? stats.map(stat => {
            return {
                name: <Title text={stat.name}/>,
                chart: (
                    <MDProgress value={calcProgress(stat.number, stat.totalNumber)} variant="gradient" label sx={{minWidth: "100px"}}/>
                ),
                number: (
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        {stat.number}
                    </MDTypography>
                ),
                totalNumber: (
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        {stat.totalNumber}
                    </MDTypography>
                ),
            }
        },) : []
    };
}
