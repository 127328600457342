export type DateStatistics = {
    date: string;
    couponsNumber: number;
    participantsNumber: number;
    receiptsNumber: number;
    receiptsSum: number;
    receiptsAvg: number;
    won: number;
    collected: number;
}

type ParticipantsByReceipts = {
    receiptsNumber: number;
    participantsNumber: number;
}

type ShopsData = {
    name: string;
    receiptsNumber: number;
    totalValue: number;
    avarageValue: number;
}

export type PrizesStock = {
    name: string;
    number: number;
    totalNumber: number;
}

export class Statistic {
    dailyStatistics: DateStatistics[];
    averageReceiptsNumber: number;
    numberOfParticipantsByNumberOfReceipts: ParticipantsByReceipts[];
    shopsData: ShopsData[];
    prizesStock: PrizesStock[];


    private constructor(dailyStatistics: DateStatistics[], averageReceiptsNumber: number, numberOfParticipantsByNumberOfReceipts: ParticipantsByReceipts[], shopsData: ShopsData[], prizesStock: PrizesStock[]) {
        this.dailyStatistics = dailyStatistics;
        this.averageReceiptsNumber = averageReceiptsNumber;
        this.numberOfParticipantsByNumberOfReceipts = numberOfParticipantsByNumberOfReceipts;
        this.shopsData = shopsData;
        this.prizesStock = prizesStock;
    }

    public static fromApiResponse(responseData: any) {
        const participantsData = responseData["participants"] ? responseData["participants"] : [];
        const couponsData = responseData["couponsData"] ? responseData["couponsData"] : [];
        const receiptsData = responseData["receipts"] ? responseData["receipts"] : [];
        const prizesData = responseData["prizesData"] ? responseData["prizesData"] : [];
        const dailyStatistics = receiptsData.map((rc:any)  => {
            const result = {
                ...rc,
                participantsNumber: [],
                won: [],
                collected: [],
            }
            const participant = participantsData.find((p:any) => p.date === rc.date);
            const won = prizesData.won.find((w: any) => w.date === rc.date);
            const collected = prizesData.collected.find((c: any) => c.date === rc.date);
            const coupons = couponsData.find((c: any) => c.date === rc.date);

            result.participantsNumber = participant ? participant.participantsNumber : 0;
            result.couponsNumber = coupons ? coupons.couponsNumber : 0;

            if(prizesData) {
                result.won = won ? won.won : 0;
                result.collected = collected ? collected.collected : 0;
            }
            return result;
        })
        const averageReceiptsNumber = responseData["averageReceiptsNumber"] ? Math.round((Number(responseData["averageReceiptsNumber"]) + Number.EPSILON) * 100) / 100 : 0;
        const numberOfParticipantsByNumberOfReceipts = responseData["numberOfParticipantsByNumberOfReceipts"] ? responseData["numberOfParticipantsByNumberOfReceipts"] : []
        const shopsData = responseData["shopsData"] ? responseData["shopsData"] : [];
        const prizesStock = responseData["prizesStock"] ? responseData["prizesStock"] : [];

        return new Statistic(
            dailyStatistics,
            averageReceiptsNumber,
            numberOfParticipantsByNumberOfReceipts,
            shopsData,
            prizesStock
        )
    }
}
