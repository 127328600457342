import React, { useContext, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/parts/MDBox";
import MDTypography from "components/parts/MDTypography";
import DataTable from "components/Tables/DataTable";
import data from "./data";
import StatisticsContext from "../../../../context/statistics-context";

const PrizesStats = () => {
  const { statistics: { prizesStock } } = useContext(StatisticsContext);
  const [prizesData, setPrizesData] = useState(data(prizesStock));

  useEffect(() => {
    setPrizesData(data(prizesStock))
  }, [prizesStock])

  return <Card>
    <MDBox
      mx={2}
      mt={-3}
      py={3}
      px={2}
      variant="gradient"
      bgColor="info"
      borderRadius="lg"
      coloredShadow="info"
    >
      <MDTypography variant="h6" color="white">
        Statystyki nagród
      </MDTypography>
    </MDBox>
    <MDBox pt={3}>
      <DataTable
        table={prizesData}
        isSorted={false}
        entriesPerPage={10}
        showTotalEntries={true}
        noEndBorder
      />
    </MDBox>
  </Card>;
};

export default PrizesStats;
