import {Card, CardContent, Tab, Tabs} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../layouts/DashboardLayout";
import {getEventBySlug} from "../../api/event-api";
import AuthContext from "../../context/auth-context";
import {useParams} from "react-router-dom";
import AlertContext from "../../context/alert-context";
import {Event} from "model/Event";
import EventContext from "../../context/event-context";
import GeneralEventInfoPanel from "./GeneralEventInfoPanel";
import EventSettingsPanel from "./EventSettingsPanel";
import EventFilesPanel from "./EventFilesPanel";
import EventShopsPanel from "./EventShopsPanel";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel = (props: TabPanelProps) => {
    const {children, value, index, ...other} = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Card
                    sx={{
                        my: 3,
                    }}
                >
                    <CardContent>
                        {children}
                    </CardContent>
                </Card>
            )}
        </div>
    );
}
const SettingsPage = () => {
    const [tab, setTab] = useState(0);
    const {user} = useContext(AuthContext);
    const {event, setEvent} = useContext(EventContext);
    const {slug} = useParams();
    const {setAlert} = useContext(AlertContext);

    useEffect(() => {
        getEventBySlug(user?.token,
            slug ? slug : '',
            (event: Event) => {
                setEvent(event)
            },
            (error: any) => {
                console.log(error)
                setAlert({title: error})
            })
    }, [slug])

    return <DashboardLayout>
        <DashboardNavbar/>
        <Tabs value={tab} onChange={(event, value) => setTab(value)} aria-label="basic tabs example">
            <Tab label="Ogólne"/>
            <Tab label="Mechanizm loterii"/>
            <Tab label="Pliki"/>
            <Tab label="Sklepy"/>
        </Tabs>
        {event && <>
            <TabPanel value={tab} index={0}>
                <GeneralEventInfoPanel/>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <EventSettingsPanel/>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <EventFilesPanel/>
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <EventShopsPanel />
            </TabPanel>
        </>}
    </DashboardLayout>
}

export default SettingsPage;