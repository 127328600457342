export type PrizeType = {
    id: number;
    name: string;
    gallery: string;
    wonDate: Date;
    collectedDate: Date | null;
}
interface PrizeInt extends PrizeType {
    participantFullName: string | null;
    participantContact: string | null;
}

export class Prize implements PrizeInt {
    collectedDate: Date | null;
    gallery: string;
    id: number;
    refNumber: number;
    name: string;
    participantContact: string | null;
    participantFullName: string | null;
    winningDate: Date;
    wonDate: Date;

    constructor(collectedDate: Date | null, gallery: string, id: number, name: string, participantContact: string | null, participantFullName: string | null, winningDate: Date, wonDate: Date, refNumber: number) {
        this.collectedDate = collectedDate;
        this.gallery = gallery;
        this.id = id;
        this.name = name;
        this.participantContact = participantContact;
        this.participantFullName = participantFullName;
        this.winningDate = winningDate;
        this.wonDate = wonDate;
        this.refNumber = refNumber;
    }


    public static fromApiResponse(response: any) {
        return new Prize(
            response["collected_date"] && new Date(response["collected_date"]),
            response["event"]["name"],
            response["id"],
            response["name"],
            response["participant"] && response["participant"]["phone"] && response["participant"]["phone"],
            response["participant"] && (response["participant"]["first_name"] + response["participant"]["last_name"]),
            response["winning_date"] && new Date(response["winning_date"]),
        response["won_date"] && new Date(response["won_date"]),
            response["ref_num"]
    );
    }

}
