/* eslint-disable react/prop-types */
// @mui material components

// Soft UI Dashboard React components
import MDBox from "components/parts/MDBox";
import MDTypography from "components/parts/MDTypography";

// Images

export default function data(shopsData) {

    const Company = ({name}) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
                {name}
            </MDTypography>
        </MDBox>
    );

    return {
        columns: [
            {Header: "Sklep", accessor: "shop", align: "left"},
            {Header: "Liczba paragonów", accessor: "receiptsNumber", width: "20%", align: "center"},
            {Header: "Łączna wartość", accessor: "totalValue", width: "20%",align: "center"},
            {Header: "Średnia wartość", accessor: "averageValue", width: "20%",align: "center"},
        ],

        rows: shopsData ? shopsData.map(shop => {
            return {
                shop: <Company name={shop.name}/>,
                receiptsNumber: (
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        {shop.receiptsNumber}
                    </MDTypography>
                ),
                totalValue: (
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        {Math.round((Number(shop.totalValue) + Number.EPSILON) * 100) / 100 + " zł"}
                    </MDTypography>
                ),
                averageValue: (
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        {Math.round((Number(shop.avarageValue) + Number.EPSILON) * 100) / 100 + " zł"}
                    </MDTypography>
                ),
            }
        },) : []
    };
}
