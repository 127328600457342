import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, useTheme} from "@mui/material";
import EventContext from "../../context/event-context";
import Typography from "@mui/material/Typography";
import {useContext, useState} from "react";
// @ts-ignore
import alert from '../../assets/images/alert.gif';
import {getResetKey, resetEvent} from "../../api/event-api";
import {Event} from "../../model/Event";
import AuthContext from "../../context/auth-context";
import {useParams} from "react-router-dom";
import AlertContext from "../../context/alert-context";

const GeneralEventInfoPanel = () => {
    const {event} = useContext(EventContext);
    const {setAlert} = useContext(AlertContext);
    const {user} = useContext(AuthContext);
    const {palette} = useTheme();
    const {slug} = useParams();
    const [openDialog, setOpenDialog] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [resetKey, setResetKey] = useState('');

    const openResetDialog = () => {
        setOpenDialog(true);
        getResetKey(
            user?.token,
            slug ? slug : '',
            (key: string) => {
                setResetKey(key)
            },
            (error: any) => {
                console.log(error)
                setAlert({title: error})
            }
        )
    }

    const handleReset = () => {
        setIsSending(true)
        resetEvent(
            user?.token,
            slug ? slug : '',
            resetKey,
            () => {
                setIsSending(false)
                setOpenDialog(false)
                setAlert({title: 'Loteria została zresetowana', color: 'success'})
            },
            (error: any) => {
                setIsSending(false)
                setOpenDialog(false)
                console.log(error)
                setAlert({title: error})
            }
        )
    }

    return <>
        <Grid
            container
            mt={2}
            rowGap={2}
            flexDirection={"column"}
        >
            {event && <>
                <TextField
                    value={event.name}
                    helperText={"Nazwa wydarzenia"}
                    disabled
                />
                <TextField
                    value={event.gallery}
                    helperText={"Nazwa centrum handlowego"}
                    disabled
                />
                <Typography variant={"button"}>Adres</Typography>
                <TextField
                    value={event.street}
                    helperText={"Ulica"}
                    disabled
                />
                <TextField
                    value={event.number}
                    helperText={"Numer budynku i lokalu"}
                    disabled
                />
                <TextField
                    value={event.zipCode}
                    helperText={"Kod pocztowy"}
                    disabled
                />
                <TextField
                    value={event.city}
                    helperText={"Miasto"}
                    disabled
                />
            </>}
        </Grid>
        <Grid
            container
            mt={2}
            rowGap={2}
            flexDirection={"column"}
            alignItems={'start'}
        >
            <Typography variant={"button"}>STREFA NIEBEZPIECZNA</Typography>
            <Button
                variant={'contained'}
                sx={{color: "white !important", backgroundColor: palette.error.main}}
                onClick={openResetDialog}
            >
                Resetuj loterię
            </Button>
        </Grid>
        <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Box
                    component={'img'}
                    src={alert}
                    sx={{
                        transform: 'rotate(90deg)',
                        width: '50px',
                        mr: '20px'
                    }}
                />
                Czy napewno chcesz zresetować loterię?
            </DialogTitle>
            <DialogContent>
                Zresetowanie loterii spowoduje nieodwracalne usnięcie danych:
                <Box component={'span'} sx={{display: 'block', mt: 2}}>- Wszystkich uczestników loterii</Box>
                <Box component={'span'} sx={{display: 'block'}}>- Kodów sms</Box>
                <Box component={'span'} sx={{display: 'block'}}>- Wyczyszczenie kuponów </Box>
                <Box component={'span'} sx={{display: 'block'}}>- Wyzerowanie statystyk </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => setOpenDialog(false)}>
                    Anuluj
                </Button>
                <Button onClick={handleReset}>Reset</Button>
            </DialogActions>
        </Dialog>
    </>
}

export default GeneralEventInfoPanel;