import React, {createContext, FC, useState} from "react";
import {Event} from 'model/Event';
import {Shop} from "../model/Shop";

export type EventContextType = {
    event: Event | null;
    setIdentifier: (value: string) => void,
    setTextField: (field: string, value: string) => void,
    setNumberField: (field: string, value: string) => void,
    setFieldRequired: (field: string) => void,
    addAgreement: () => void,
    removeAgreement: () => void,
    setAgreement: (index: number, value: string) => void,
    setEvent: (event: Event) => void;
    setShops: (shops: Shop[]) => void;
}

const InitialEventContext: EventContextType = {
    event: null,
    setIdentifier: (value: string) => {
    },
    setTextField: (field: string, value: string) => {
    },
    setNumberField: (field: string, value: string) => {
    },
    setFieldRequired: (field) => {
    },
    setEvent: (event: Event) => {
    },
    addAgreement: () => {
    },
    removeAgreement: () => {
    },
    setAgreement: (index: number, value: string) => {
    },
    setShops:(shops: Shop[]) => {}
}

const EventContext = createContext(InitialEventContext);

export const EventContextProvider: FC = ({children}) => {
    const [event, setEvent] = useState<Event | null>(null);

    const setIdentifier = (value: string) => {
        if (event?.settings) {
            const updatedSettings = {
                ...event?.settings,
                identifier: value
            }
            setEvent({...event, settings: updatedSettings})
        }
    }

    const setFieldRequired = (field: string) => {
        if (event?.settings) {
            let updatedSettings = {...event.settings}
            switch (field) {
                case 'firstName': {
                    updatedSettings = {...updatedSettings, firstNameRequired: !updatedSettings.firstNameRequired}
                    break;
                }
                case 'lastName': {
                    updatedSettings = {...updatedSettings, lastNameRequired: !updatedSettings.lastNameRequired}
                    break;
                }
                case 'zipCode': {
                    updatedSettings = {...updatedSettings, zipCodeRequired: !updatedSettings.zipCodeRequired}
                    break;
                }
                case 'email': {
                    updatedSettings = {...updatedSettings, emailRequired: !updatedSettings.emailRequired}
                    break;
                }
                case 'phone': {
                    updatedSettings = {...updatedSettings, phoneRequired: !updatedSettings.phoneRequired}
                    break;
                }
                case 'extraChances': {
                    updatedSettings = {...updatedSettings, extraChances: !updatedSettings.extraChances}
                    break;
                }
                case 'loyaltyCard': {
                    updatedSettings = {
                        ...updatedSettings,
                        loyaltyCardNumberRequired: !updatedSettings.loyaltyCardNumberRequired
                    }
                    break;
                }
            }
            setEvent({...event, settings: updatedSettings})
        }
    }

    const setTextField = (field: string, value: string) => {
        if (event?.settings) {
            let updatedSettings = {...event.settings}
            // @ts-ignore
            updatedSettings[field] = value
            setEvent({...event, settings: updatedSettings})
        }
    }

    const setNumberField = (field: string, value: string) => {
        if (event?.settings) {
            let updatedSettings = {...event.settings}
            // @ts-ignore
            updatedSettings[field] = Number(value)
            setEvent({...event, settings: updatedSettings})
        }
    }

    const addAgreement = () => {
        if (event?.settings?.agreements) {
            let updatedAgreements = [...event.settings.agreements, '']
            let updatedSettings = {...event.settings, agreements: updatedAgreements}
            setEvent({...event, settings: updatedSettings})
        }
    }
    const removeAgreement = () => {
        if (event?.settings?.agreements) {
            event.settings.agreements.pop()
            let updatedAgreements = [...event.settings.agreements]
            let updatedSettings = {...event.settings, agreements: updatedAgreements}
            setEvent({...event, settings: updatedSettings})
        }
    }
    const setAgreement = (index: number, value: string) => {
        if (event?.settings?.agreements) {
            let updatedAgreements = [...event.settings.agreements]
            updatedAgreements[index] = value
            let updatedSettings = {...event.settings, agreements: updatedAgreements}
            setEvent({...event, settings: updatedSettings})
        }
    }

    const setShops = (shops: Shop[]) => {
        if (event) {
            setEvent({...event, shops: shops})
        }
    }

    const contextValue: EventContextType = {
        ...InitialEventContext,
        event,
        setIdentifier,
        setTextField,
        setNumberField,
        setFieldRequired,
        addAgreement,
        setAgreement,
        removeAgreement,
        setEvent,
        setShops
    }

    return <EventContext.Provider value={contextValue}>{children}</EventContext.Provider>
}

export default EventContext;
