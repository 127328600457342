/**
 All of the routes for the Soft UI Dashboard React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Sidenav.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 2. The `type` key with the `hidden` value is used for a route without menu item inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

import Dashboard from "./pages/dashboard";
import Tables from "./pages/tables";
import Profile from "./pages/profile";
import SignIn from "./pages/authentication/sign-in";
import Icon from "@mui/material/Icon";
import React, {ReactElement} from "react";
import {Navigate, Route} from "react-router-dom";
import {AuthContextType} from "./context/auth-context";
import Prizes from "./pages/prizes";
import {Event} from "./model/Event";
import SettingsPage from "./pages/settings";
import HomePage from "./pages/homepage";

type RouteType = {
    type: string;
    title?: string;
    collapse?: RouteType[];
    access?: string;
    featureFlag?: string;
    role: "user" | "admin" | "reviewer" | "lottery";
    name: string;
    key: string;
    icon?: ReactElement;
    route?: string;
    path?: string;
    component?: ReactElement;
}

const getRoutes = (events: Event[]): RouteType[] => {
    return events.flatMap(event => {
            return [
                {
                    type: "title",
                    title: event.name,
                    role: "reviewer",
                    name: event.name,
                    key: event.slug,
                },
                {
                    type: "collapse",
                    role: "reviewer",
                    name: "Statystyki",
                    key: "dashboard-" + event.slug,
                    route: `/${event.slug}`,
                    path: `/:slug`,
                    icon: <Icon fontSize="small">dashboard</Icon>,
                    component: <Dashboard/>,
                },
                {
                    type: "collapse",
                    role: "admin",
                    name: "Uczestnicy",
                    key: "uczestnicy-" + event.slug,
                    icon: <Icon fontSize="small">table_view</Icon>,
                    route: `/${event.slug}/uczestnicy`,
                    path: `/:slug/uczestnicy`,
                    component: <Tables/>,
                },
                {
                    type: "collapse",
                    role: "admin",
                    name: "Nagrody",
                    key: "nagrody-" + event.slug,
                    icon: <Icon fontSize="small">emoji_events</Icon>,
                    route: `/${event.slug}/nagrody`,
                    path: `/:slug/nagrody`,
                    component: <Prizes/>,
                },
                {
                    type: "collapse",
                    role: "admin",
                    name: "Ustawienia",
                    key: "ustawienia-" + event.slug,
                    icon: <Icon fontSize="small">settings</Icon>,
                    route: `/${event.slug}/ustawienia`,
                    path: `/:slug/ustawienia`,
                    component: <SettingsPage/>,
                },
                {
                    type: "hidden",
                    role: "admin",
                    name: "Dane użytkownika",
                    key: "participant-" + event.slug,
                    icon: <Icon fontSize="small">account_circle</Icon>,
                    route: `/${event.slug}/uczestnicy/:id`,
                    path: `/:slug/uczestnicy/:id`,
                    component: <Profile/>,
                },
                {
                    type: "divider",
                    role: "reviewer",
                    name: "divider",
                    key: "divider-" + event.slug,
                },
            ]
        }
    );
}

// FILTER ROUTES BASED ON ROLE AND ACCESS

const filterRoutesData = (authCtx: AuthContextType, routesList: RouteType[]) => {
    if (!authCtx.isLoggedIn || !authCtx.user) {
        return [];
    }
    const {role} = authCtx.user;
    const routes = [] as any[];

    routesList.forEach((route) => {
        // CHECK LOGGED IN USER
        if (!authCtx.isLoggedIn) {
            return;
        }

        switch (route.role) {
            case "admin": {
                if (role.priority == 10) {
                    routes.push(route);
                }
                break;
            }
            case "reviewer": {
                if (role.priority >= 5) {
                    routes.push(route);
                }
                break;
            }
            default: {
                return null;
            }
        }
    });
    return routes;
}

const routes = (authCtx: AuthContextType) => {
    const routesList = getRoutes(authCtx.user?.access ? authCtx.user.access : []);
    const routesData = filterRoutesData(authCtx, routesList);
    const allRoutes = routesData.map((route) => {
        return route.route && <Route path={route.path} element={route.component} key={route.key}/>
    });
    // COMMON ROUTES
    if (!authCtx.isLoggedIn || !authCtx.user?.access) {
        allRoutes.push(<Route path={"/login"} element={<SignIn/>} key={"login"}/>)
        allRoutes.push(<Route path="*" key="other" element={<Navigate to="/login"/>}/>);
    } else {
        allRoutes.push(<Route path="/" key="home-page" element={<HomePage/>}/>);
        allRoutes.push(<Route path="*" key="other"
                              element={<Navigate
                                  to={"/" + (authCtx.user?.access[0] ? authCtx.user?.access[0]?.slug : "")}/>}/>);
    }
    return [allRoutes, routesData];
}

export default routes;
