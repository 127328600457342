import axios from "./api-client";
import {Coupon, Participant, Receipt} from "../model/Participant";

const config = {
    headers: {
        "Accept-Content": "application/json"
    }
}

export const getAllParticipantsBySlug = (token: string | undefined, slug: string, handleData: (participants: Participant[]) => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/api/events/${slug}/participants/all` : "";
    const config = {
        headers: {
            'Accept-Content': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    axios.get(url, config).then((response) => {
        if (response.status == 200) {
            const participants = response.data.map((item: any) => Participant.fromApiResponse(item));
            handleData(participants);
        } else if (response.status != 200) {
            handleData([] as Participant[]);
        } else {
            onError && onError(JSON.stringify(response.data));
        }
    }).catch((error) => {
        console.error(error?.response?.data ? error?.response?.data?.message : error);
        onError && onError(error?.response?.data ? error?.response?.data?.message : "Błąd serwera!");
    })
}

export const getParticipantById = (token: string | undefined, slug: string, id: number, handleData: (participant: Participant) => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/api/events/${slug}/participants/${id}`: "";
    const config = {
        headers: {
            'Accept-Content': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    axios.get(url, config).then((response) => {
        if (response.status == 200) {
            const participant = Participant.fromApiResponse(response.data);
            console.log(participant)
            handleData(participant);
        } else if (response.status != 200) {
            handleData({} as Participant);
        } else {
            onError && onError(JSON.stringify(response.data));
        }
    }).catch((error) => {
        console.error(error.response?.data ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : "Błąd serwera!");
    })
}

export const updateParticipant = (token: string | undefined, participant: Participant, handleData?: (participant: Participant) => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/participants/${participant.id}` : '';
    const config = {
        headers: {
            'Accept-Content': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    axios.patch(url, participant, config).then((response) => {
        if (response.status == 200) {
            handleData && handleData(Participant.fromApiResponse(response.data));
        } else {
            onError && onError(JSON.stringify(response.data));
        }
    }).catch((error) => {
        console.error(error.response.data ? error.response.data.message : error);
        onError && onError(error.response.data ? error.response.data.message : "Błąd serwera!");
    })
}
