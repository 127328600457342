import axios from "axios";

  axios.interceptors.response.use((response) => response, (error) => {
    if((error.response.status === 419 || error.response.status === 401) && window.location.pathname !== "/login") {
        localStorage.removeItem("user");
        window.location.reload();
        error.message = "Zaloguj się ponownie";
    }
    if(error.response.status == 403) {
        error.message = "Nie masz uprawnień do tego zasobu";
    }
    return Promise.reject(error);

})

export default axios;
