/* eslint-disable react/prop-types */

// Soft UI Dashboard React components

import {Button, Icon} from "@mui/material";

export default function data(prizes, slug, onEdit, onDelete) {
    return {
        columns: [
            {Header: "Id", accessor: "id", align: "left"},
            {Header: "Nagroda", accessor: "prize", align: "left"},
            {Header: "Do wygrania o", accessor: "winning", align: "left"},
            {Header: "Wygrana", accessor: "won", align: "left"},
            {Header: "Odebrana", accessor: "collected", align: "left"},
            {Header: "Uczestnik", accessor: "participant", align: "left"},
            {Header: "Kontakt", accessor: "contact", align: "left"},
            {Header: "Edytuj", accessor: "edit", align: "center"},
            {Header: "Usuń", accessor: "delete", align: "center"},
        ],

        rows: prizes.map(p => {
            return {
                id: p.refNumber,
                prize: p.name,
                winning: p.winningDate ? p.winningDate.toLocaleString() : 'Błąd',
                won: p.wonDate ? p.wonDate.toLocaleString()  : "Nie wygrana",
                collected: p.collectedDate ? p.collectedDate.toLocaleString() : "Nie odebrana",
                participant: p.participantFullName ? p.participantFullName : '',
                contact: p.participantContact,
                edit: (
                    <Button onClick={() => onEdit(p)}>
                        <Icon>edit</Icon>
                    </Button>
                ),
                delete: (
                    <Button onClick={() => onDelete(p)}>
                        <Icon color={"error"}>delete</Icon>
                    </Button>
                )
            }
        })
    };
}
