import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDBox from "components/parts/MDBox";
import MDTypography from "components/parts/MDTypography";
import DashboardLayout from "layouts//DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Header from "pages/profile/components/Header";
import {useContext, useEffect, useState} from "react";
import {CircularProgress, ClickAwayListener, TextField, Tooltip} from "@mui/material";
import {useParams} from "react-router-dom";
import AlertContext from "context/alert-context";
import validator from 'validator';
import {getParticipantById, updateParticipant} from "../../api/participant-api";
import AuthContext from "../../context/auth-context";
import IconButton from "@mui/material/IconButton";
import AssignmentIcon from '@mui/icons-material/Assignment';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {updateReceipt} from "../../api/receipts-api";


const Overview = () => {
    const [participant, setParticipant] = useState({});
    const [errors, setErrors] = useState({
        phone: '',
        email: '',
    })
    const alertCtx = useContext(AlertContext);
    const authCtx = useContext(AuthContext);
    const {slug, id} = useParams();
    const [editedReceipt, setEditedReceipt] = useState();
    const [saving, setSaving] = useState(false);

    const isEditor = authCtx.user.role.priority === 10;

    const getParticipant = () => {
        getParticipantById(authCtx.user?.token, slug, id, participant => setParticipant(participant), error => alertCtx.setAlert({title: error}));
    }

    useEffect(() => {
        getParticipant()
    }, [])

    const handleStringValueChange = (event, fieldName) => {
        const value = event.target.value;
        if (value !== "") {
            const editedParticipant = {...participant};
            editedParticipant[fieldName] = value;
            setParticipant(editedParticipant);
        }
        if (fieldName === 'phone') {
            validatePhone(value);
        }
        if (fieldName === 'email') {
            validateEmail(value);
        }
    }

    const validatePhone = (phone) => {
        if (!validator.isMobilePhone(phone, ['pl-PL'])) {
            setErrors({...errors, phone: 'Zły format numeru telefonu'})
        } else {
            setErrors({...errors, phone: ''})
        }
    }

    const validateEmail = (email) => {
        if (!validator.isEmail(email, 'PL')) {
            setErrors({...errors, email: 'Zły format emaila'})
        } else {
            setErrors({...errors, email: ''})
        }
    }
    const validateForm = (errors) => {
        let isValid = true;
        for (const error in errors) {
            const fieldValue = errors[error];
            if (fieldValue !== "" && !Array.isArray(fieldValue)) {
                isValid = false;
            }
            if (Array.isArray(fieldValue)) {
                fieldValue.forEach((item) => {
                    if (!validateForm(item)) {
                        isValid = false;
                    }
                })
            }
        }
        return isValid;
    }

    const getGrantedBy = (value) => {
        switch (value) {
            case "happy_hour":
                return "Bonusowe godziny";
            case "agreements":
                return "Zgody marketingowe";
            default:
                return "Paragony";
        }
    }

    const handleSave = () => {
        if (!validateForm(errors)) {
            alertCtx.setAlert({title: "Formularz zawiera błędy", color: "error"});
            return;
        }
        const simpleData = {...participant, receipts: [], prizes: [], coupons: []};
        updateParticipant(simpleData, participant => alertCtx.setAlert({
            title: "Nowe dane zostały zapisane",
            color: "success"
        }), error => alertCtx.setAlert({title: error}))
    }

    const handleEditReceiptNumberChange = (event) => {
        const value = event.target.value
        setEditedReceipt((prev) => ({...prev, number: value}))
    }

    const handleEditReceiptAmountChange = (event) => {
        const value = Number(event.target.value)
        setEditedReceipt((prev) => ({...prev, amount: value}))
    }

    const handleSaveReceipt = (old) => {
        if(old.amount === editedReceipt.amount && old.number === editedReceipt.number) {
            setEditedReceipt(undefined)
            return;
        }
        setSaving(true)
        updateReceipt(
            authCtx.user?.token,
            slug, editedReceipt,
            (data) => {
                getParticipant()
                setSaving(false);
                setEditedReceipt(undefined)
            },
            error => {
                alertCtx.setAlert({title: error})
                setSaving(false);
                setEditedReceipt(undefined)
            })

    }

    const renderEditButton = (receipt) => {
        if (saving && editedReceipt && (editedReceipt.id === receipt.id)) {
            return <CircularProgress/>
        }
        if (editedReceipt && (editedReceipt.id === receipt.id)) {
            return <Tooltip title="Zapisz zmiany">
                <IconButton color="secondary" onClick={() => handleSaveReceipt(receipt)}>
                    <SaveIcon/>
                </IconButton>
            </Tooltip>
        } else {
            return <Tooltip title="Edytuj paragon">
                <IconButton color="secondary" onClick={() => setEditedReceipt(receipt)}>
                    <EditIcon/>
                </IconButton>
            </Tooltip>
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mb={2}/>
            <Header
                fullName={(participant.firstName ? participant.firstName : "") + " " + (participant.lastName ? participant.lastName : "")}
                email={participant.email}
                // onSave={isEditor && handleSave}
            >
                <MDBox mt={5} mb={3}>
                    <Grid container spacing={1}>
                        <Grid container item xs={12} md={6}>
                            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                                Dane personalne
                            </MDTypography>
                            <TextField sx={{mt: 2}} disabled={!isEditor} fullWidth label="Imię"
                                       defaultValue={participant.firstName}
                                       value={participant.firstName} onChange={(event) => {
                                handleStringValueChange(event, "firstName")
                            }} InputLabelProps={{shrink: true}}/>
                            <TextField sx={{mt: 2}} disabled={!isEditor} fullWidth label="Nazwisko"
                                       defaultValue={participant.lastName}
                                       value={participant.lastName} onChange={(event) => {
                                handleStringValueChange(event, "lastName")
                            }} InputLabelProps={{shrink: true}}/>
                            <TextField sx={{mt: 2}} disabled={!isEditor} fullWidth label="Numer karty lojalnościowej"
                                       defaultValue={participant.loyaltyCardNumber}
                                       value={participant.loyaltyCardNumber} onChange={(event) => {
                                handleStringValueChange(event, "loyaltyCardNumber")
                            }} InputLabelProps={{shrink: true}}/>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{display: "flex"}}>
                            <Divider orientation="vertical" sx={{display: {xs: "none", md: "block"}, mx: 1}}/>
                            <Grid item container flexDirection={"column"}>
                                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                                    Dane kontaktowe
                                </MDTypography>
                                <TextField sx={{mt: 2}} disabled={!isEditor} fullWidth label="Telefon"
                                           defaultValue={participant.phone}
                                           error={!!errors.phone} helperText={errors.phone}
                                           value={participant.phone} onChange={(event) => {
                                    handleStringValueChange(event, "phone")
                                }} InputLabelProps={{shrink: true}}/>
                                <TextField sx={{mt: 2}} fullWidth label="Email" disabled={!isEditor}
                                           defaultValue={participant.email}
                                           error={!!errors.email} helperText={errors.email}
                                           value={participant.email} onChange={(event) => {
                                    handleStringValueChange(event, "email")
                                }} InputLabelProps={{shrink: true}}/>
                                <TextField sx={{mt: 2}} fullWidth label="Kod pocztowy" disabled={!isEditor}
                                           defaultValue={participant.zipCode}
                                           error={!!errors.zipCode} helperText={errors.zipCode}
                                           value={participant.zipCode} onChange={(event) => {
                                    handleStringValueChange(event, "zipCode")
                                }} InputLabelProps={{shrink: true}}/>
                                {/*<Grid item mt={2} container alignItems={"center"}>*/}
                                {/*    <Grid item xs={6}>*/}
                                {/*        <FormControlLabel control={<Checkbox disabled={!isEditor} checked={!!participant.cashback} size={"large"}/>} label="Cashback" />*/}
                                {/*    </Grid>*/}
                                {/*    <Grid item xs={6}>*/}
                                {/*        <FormControlLabel  control={<Checkbox disabled={!isEditor} checked={!!participant.additionalAgreements} size={"large"} />} label="Dodatkowe zgody marketingowe" />*/}
                                {/*    </Grid>*/}
                                {/*</Grid>*/}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{display: "flex", mt: {md: 5}}}>
                            <ClickAwayListener onClickAway={() => setEditedReceipt(undefined)}>

                                <Grid item container flexDirection={"column"}>
                                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                                        Paragony
                                    </MDTypography>
                                    {participant.receipts && participant.receipts.length > 0 ? participant.receipts.map((rc, index) => {
                                            return (<>
                                                    <MDTypography variant="button" color="text" fontWeight="regular">
                                                        {`Paragon ${index + 1}`}
                                                    </MDTypography>
                                                    <Grid item container justifyContent={"space-between"} mt={2}
                                                          spacing={1}>
                                                        <Grid item xs={12} md={1}>
                                                            <TextField disabled fullWidth label="Id" value={rc.id}/>
                                                        </Grid>
                                                        <Grid item xs={12} md={3}>
                                                            <TextField disabled fullWidth label="Data"
                                                                       value={rc.date.toLocaleString()}/>
                                                        </Grid>
                                                        <Grid item xs={12} md={2}>
                                                            {editedReceipt && (editedReceipt.id === rc.id) ?
                                                                <TextField fullWidth label="Numer"
                                                                           value={editedReceipt.number}
                                                                           onChange={(event) => handleEditReceiptNumberChange(event)}/>
                                                                : <TextField disabled fullWidth label="Numer"
                                                                             value={rc.number}/>}
                                                        </Grid>
                                                        <Grid item xs={12} md={3}>
                                                            <TextField disabled fullWidth label="Sklep"
                                                                       value={rc.shopName}/>
                                                        </Grid>
                                                        <Grid item xs={12} md={2}>
                                                            {editedReceipt && (editedReceipt.id === rc.id) ?
                                                                <TextField fullWidth label="Kwota" type='number'
                                                                           value={editedReceipt.amount}
                                                                           onChange={(event) => handleEditReceiptAmountChange(event)}/>
                                                                : <TextField disabled fullWidth label="Kwota"
                                                                             value={rc.amount}/>}
                                                        </Grid>
                                                        <Grid item container xs={12} md={1} justifyContent={'center'}>
                                                            {renderEditButton(rc)}
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )
                                        }) :
                                        <MDTypography variant="button" color="text" fontWeight="regular">
                                            Brak
                                        </MDTypography>
                                    }
                                </Grid>
                            </ClickAwayListener>

                        </Grid>
                        <Grid item xs={12} sx={{display: "flex", mt: {md: 5}}}>
                            <Grid item container flexDirection={"column"}>
                                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                                    Kupony
                                </MDTypography>
                                {participant.coupons && participant.coupons.length > 0 ? participant.coupons.map((cp, index) => {
                                    return (<>
                                            <MDTypography variant="button" color="text" fontWeight="regular">
                                                {`Kupon ${index + 1}`}
                                            </MDTypography>
                                            <Grid item container justifyContent={"space-between"} mt={2} spacing={1}>
                                                <Grid item xs={12} md={3}>
                                                    <TextField disabled fullWidth label="Kod" value={cp.code}/>
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                    <TextField disabled fullWidth label="Pozostałe szanse"
                                                               value={cp.chances}/>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <TextField disabled fullWidth label="Użyty"
                                                               value={cp.updatedAt && cp.updatedAt.toLocaleString()}/>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <TextField disabled fullWidth label="Przyznany za"
                                                               value={getGrantedBy(cp.grantedBy)}/>
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <TextField disabled fullWidth label="Id paragonów"
                                                               value={cp.receiptsIds}/>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                }) : <MDTypography variant="button" color="text" fontWeight="regular">
                                    Brak
                                </MDTypography>}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{display: "flex", mt: {md: 5}}}>
                            <Grid item container flexDirection={"column"}>
                                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                                    Nagrody
                                </MDTypography>
                                {participant.prizes && participant.prizes.length > 0 ? participant.prizes.map((pz, index) => {
                                        const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/api/prizes/${pz.db_id}/reports/${pz.report}` : '';
                                        return (<>
                                                <MDTypography variant="button" color="text" fontWeight="regular">
                                                    {`Nagroda ${index + 1}`}
                                                </MDTypography>
                                                <Grid item container justifyContent={"space-between"} mt={2} spacing={1}>
                                                    <Grid item xs={12} md={1}>
                                                        <TextField disabled fullWidth label="Id" value={pz.id}/>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField disabled fullWidth label="Nagroda" value={pz.name}/>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <TextField disabled fullWidth label="Wygrana"
                                                                   value={pz.wonDate && pz.wonDate.toLocaleString()}/>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <TextField disabled fullWidth label="Odebrana"
                                                                   value={pz.collectedDate && pz.collectedDate.toLocaleString()}/>
                                                    </Grid>
                                                    <Grid item xs={12} md={1}>
                                                        {pz.report &&
                                                            <Tooltip title="Raport odebrania nagrody">
                                                                <IconButton color="secondary" href={url}>
                                                                    <AssignmentIcon/>
                                                                </IconButton>
                                                            </Tooltip>}
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )
                                    }) :
                                    <MDTypography variant="button" color="text" fontWeight="regular">
                                        Brak
                                    </MDTypography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </MDBox>
            </Header>
        </DashboardLayout>
    )
        ;
}

export default Overview;
