import React, {useContext, useEffect, useState} from "react";
import {Routes, useLocation} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "./components/parts/MDBox";
import Sidenav from "./components/Sidenav";
import Configurator from "./components/Configurator";
import theme from "./assets/theme";
import themeDark from "./assets/theme-dark";
import routes from "./routes";
import {setMiniSidenav, setOpenConfigurator, useMaterialUIController} from "./context/material-ui-context";
import brandWhite from "./assets/images/logo-ct.png";
import brandDark from "./assets/images/logo-ct-dark.png";
import AuthContext from "./context/auth-context";
import AlertContext from "./context/alert-context";
import MDSnackbar from "./components/parts/MDSnackbar";

export default function App() {
    const authCtx = useContext(AuthContext);
    const alertCtx = useContext(AlertContext);
    const [allRoutes, routesData] = routes(authCtx);
    const [controller, dispatch] = useMaterialUIController();
    const {
        miniSidenav,
        layout,
        openConfigurator,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const {pathname} = useLocation();

    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    const configsButton = (
        <MDBox
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            color="dark"
            sx={{
                cursor: "pointer",
                position: "fixed",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "3.25rem",
                height: "3.25rem",
                right: "2rem",
                bottom: "2rem",
                zIndex: 99
            }}
            onClick={handleConfiguratorOpen}
        >
            <Icon fontSize="small" color="inherit">
                settings
            </Icon>
        </MDBox>
    );

    useEffect(() => {
        if (document.documentElement && document.scrollingElement) {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
        }
    }, [pathname]);

    useEffect(() => {
    },[])

    // getGalleryShops("galeria-2", (shops) => console.log(shops), (error) => console.log(error));
    // collectPrize(4, "galeria-2");
    // const receipts = [{amount: 3000, shopName: "4F", date: new Date("2022-03-12 20:18:52"), number: "Paragon 1"}];
    // const participant = new Participant(null,"Test","Test","535122858", "88-888",null, receipts,[]);
    // registerParticipant(participant, "galeria-1",(participant)=> console.log("GIT"));
    // getParticipantByPhone('352-633-1286', (participant => console.log(participant)));
    // winPrize("2552322", 'galeria-1', lotteryApiResponse => console.log(lotteryApiResponse), error => console.log(error));
    // getAllParticipantsByGallerySlug('galeria-1', participants => {console.log(participants)});

    return (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline/>
            {layout === "dashboard" && authCtx.isLoggedIn && (
                <>
                    <Sidenav
                        color={sidenavColor}
                        brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                        brandName="Panel administratora"
                        routes={routesData}
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                    />
                    <Configurator/>
                    {configsButton}
                </>
            )}
            {layout === "vr" && authCtx.isLoggedIn && <Configurator/>}
            <Routes>
                {allRoutes}
            </Routes>
            <MDSnackbar bgWhite={false} icon={alertCtx.icon} open={alertCtx.open} color={alertCtx.color}
                        close={alertCtx.close}
                        title={alertCtx.title} content={alertCtx.content} dateTime={alertCtx.dateTime}
                        xl={alertCtx.xl}/>
        </ThemeProvider>
    );
}
