import {useContext, useState} from "react";
import Card from "@mui/material/Card";
import MDBox from "components/parts/MDBox";
import MDTypography from "components/parts/MDTypography";
import MDInput from "components/parts/MDInput";
import MDButton from "components/parts/MDButton";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import AuthContext from "context/auth-context";
import FrontPageLayout from "layouts//FrontPageLayout";

const SignIn = () =>  {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const {login} = useContext(AuthContext);

    const handleLogin = () => {
        login(email,password);
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

  return (
    <FrontPageLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Zaloguj się
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="email" label="Email" value={email} onChange={handleEmailChange} fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" value={password} onChange={handlePasswordChange} fullWidth />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleLogin}>
                Zaloguj
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </FrontPageLayout>
  );
}

export default SignIn;
