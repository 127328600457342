import {PrizeType} from "./Prize";

export type Receipt = {
    id?: number;
    amount: number;
    shopName: string;
    date: Date;
    number: string;
}

export type Coupon = {
    id?: number;
    code: string;
    chances?: string;
    grantedBy: string;
    report?: string;
    receiptsIds?: string | null;
}

export class Participant {
    id: number | null;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    zipCode: string;
    loyaltyCardNumber: string;
    // cashback: boolean;
    // additionalAgreements: boolean;
    created: Date | null;
    receipts: Receipt[] | null;
    prizes: PrizeType[] | null;
    coupons: Coupon[] | null;

    private constructor(id: number, firstName: string, lastName: string, phone: string, email: string, zipCode: string, loyaltyCardNumber: string, created: Date, receipts: Receipt[], prizes: PrizeType[], coupons: Coupon[]) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phone = phone;
        this.email = email;
        this.zipCode = zipCode;
        this.loyaltyCardNumber = loyaltyCardNumber;
        // this.cashback = cashback;
        // this.additionalAgreements = additionalAgreements;
        this.created = created;
        this.receipts = receipts;
        this.prizes = prizes;
        this.coupons = coupons;
    }

    public static fromApiResponse(response: any) {
        return new Participant(
            response["id"],
            response["first_name"],
            response["last_name"],
            response["phone"],
            response["email"],
            response["zip_code"],
            response["loyalty_card_number"],
            // response["cashback"] == '1',
            // response["additional_agreements"] == '1',
            new Date(response["created_at"]),
            response["receipts"] && response["receipts"].map((receipt: any) => {
                return {
                    id: receipt["id"],
                    amount: receipt["amount"],
                    shopName: receipt["shop"] ? receipt["shop"]["name"] : '',
                    number: receipt["receipt_number"],
                    date: new Date(receipt["receipt_date"]),
                }
            }),
            response["prizes"] && response["prizes"].map((prize: any) => {
                return {
                    db_id: prize["id"],
                    id: prize["ref_num"],
                    name: prize["name"],
                    report: prize["media"] && prize["media"].length > 0 && prize["media"][0]["uuid"],
                    gallery: prize["event"]["name"],
                    wonDate: prize["won_date"] && new Date(prize["won_date"]),
                    collectedDate: prize["collected_date"] && new Date(prize["collected_date"]),
                }
            }),
            response["coupons"] && response["coupons"].map((coupon: any) => {
                return {
                    id: coupon["id"],
                    chances: coupon["chances"],
                    code: coupon["code"],
                    grantedBy: coupon["granted_by"],
                    receiptsIds: coupon["receipts"] && coupon["receipts"].map((r: any) => r.id).join(", "),
                    createdAt: new Date(coupon["created_at"]),
                    updatedAt: new Date(coupon["updated_at"]),
                }
            })
        )
    }
}
