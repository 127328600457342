import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    Icon,
    IconButton,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import EventContext from "../../context/event-context";
import {useContext} from "react";
import {EMAIL_ID_FIELD, LOYALTY_CARD_NUMBER_ID_FIELD, PHONE_ID_FIELD} from "../../model/Settings";
import {Event, INSTANT_WIN_EVENT_TYPE, SCANNED_COUPONS_EVENT_TYPE, SMS_EVENT_TYPE} from "model/Event";
import {MuiColorInput} from 'mui-color-input'
import {persistSettings} from "../../api/event-api";
import AuthContext from "../../context/auth-context";
import {useParams} from "react-router-dom";
import AlertContext from "../../context/alert-context";

const EventSettingsPanel = () => {
    const ctx = useContext(EventContext);
    const {user} = useContext(AuthContext);
    const {slug} = useParams();
    const {setAlert} = useContext(AlertContext);

    const saveSettings = () => {
        if (ctx.event?.settings) {
            persistSettings(user?.token,
                slug ? slug : '',
                ctx.event.settings,
                (event: Event) => {
                    ctx.setEvent(event)
                    setAlert({title: 'Zaktualizowano ustawienia', color: 'success'})
                },
                (error: any) => {
                    console.log(error)
                    setAlert({title: error})
                })

        }
    }

    return <Grid
        container
        mt={2}
        rowGap={2}
        alignItems={"start"}
        flexDirection={"column"}
    >
        {ctx.event?.settings && <>
            <Grid item container justifyContent={"space-between"} wrap={"nowrap"}>
                <Grid item container flexDirection="column" xs={6} alignItems={"start"}>
                    <Typography variant={"button"}>Rodzaj wydarzenia</Typography>
                    <Select
                        value={ctx.event.settings.eventType}
                        label="Rodzaj wydarzenia"
                        sx={{minWidth: "300px", minHeight: "30px"}}
                        onChange={(event) => ctx.setTextField('eventType', event.target.value)}
                    >
                        <MenuItem value={SMS_EVENT_TYPE}>Loteria z kodami sms</MenuItem>
                        <MenuItem value={SCANNED_COUPONS_EVENT_TYPE}>Loteria ze skanowanymi kuponami</MenuItem>
                        <MenuItem value={INSTANT_WIN_EVENT_TYPE}>Akcja z natychmiastową wygraną</MenuItem>
                    </Select>
                </Grid>
                <Grid item container xs={6} justifyContent={"end"}>
                    <Button variant={"contained"} sx={{color: "white !important"}} onClick={saveSettings}>Zapisz
                        zmiany</Button>
                </Grid>
            </Grid>
            <Typography variant={"button"} mt={2}>Identyfikator uczestnika</Typography>
            <Select
                value={ctx.event.settings.identifier}
                label="Identyfikator"
                sx={{minWidth: "300px", minHeight: "30px"}}
                onChange={(event) => ctx.setIdentifier(event.target.value)}
            >
                <MenuItem value={PHONE_ID_FIELD}>Telefon</MenuItem>
                <MenuItem value={EMAIL_ID_FIELD}>Email</MenuItem>
                <MenuItem value={LOYALTY_CARD_NUMBER_ID_FIELD}>Numer kary lojalnościowej</MenuItem>
            </Select>
            <Typography variant={"button"} mt={2}>Zbierane informacje o uczestniku</Typography>
            <Grid item container>
                <Grid item container xs={6}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={ctx.event.settings.firstNameRequired}
                                                             onChange={() => ctx.setFieldRequired('firstName')}/>}
                                          label="Imię"/>
                        <FormControlLabel control={<Checkbox checked={ctx.event.settings.lastNameRequired}
                                                             onChange={() => ctx.setFieldRequired('lastName')}/>}
                                          label="Nazwisko"/>
                        <FormControlLabel control={<Checkbox checked={ctx.event.settings.zipCodeRequired}
                                                             onChange={() => ctx.setFieldRequired('zipCode')}/>}
                                          label="Kod pocztowy"/>
                    </FormGroup>
                </Grid>
                <Grid item container xs={6}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={ctx.event.settings.phoneRequired}
                                                             onChange={() => ctx.setFieldRequired('phone')}/>}
                                          label="Telefon"/>
                        <FormControlLabel control={<Checkbox checked={ctx.event.settings.emailRequired}
                                                             onChange={() => ctx.setFieldRequired('email')}/>}
                                          label="Email"/>
                        <FormControlLabel control={<Checkbox checked={ctx.event.settings.loyaltyCardNumberRequired}
                                                             onChange={() => ctx.setFieldRequired('loyaltyCard')}/>}
                                          label="Numer karty lojalnościowej"/>
                    </FormGroup>
                </Grid>
            </Grid>
            <Typography variant={"button"} mt={2}>Parametry wydarzenia</Typography>
            <Grid item container justifyContent={"space-between"}>
                <Grid item container xs={5}>
                    <TextField
                        fullWidth
                        value={ctx.event.settings.maxReceipts}
                        helperText={"Maksymalna ilość paragonów przy jednej rejestracji"}
                        onChange={(event) => ctx.setNumberField('maxReceipts', event.target.value)}
                        type={"number"}
                        InputProps={{
                            inputProps: {min: 1, step: 1}
                        }}
                    />
                    <TextField
                        fullWidth
                        value={ctx.event.settings.chanceStep}
                        helperText={"Co jaką kwotę przyznawana jest szansa/kod"}
                        onChange={(event) => ctx.setNumberField('chanceStep', event.target.value)}
                        type={"number"}
                        InputProps={{
                            inputProps: {min: 1, step: 1}
                        }}
                    />
                </Grid>
                <Grid item container xs={5}>
                    <TextField
                        fullWidth
                        value={ctx.event.settings.maxChances}
                        helperText={"Maksymalna ilość kodów/kuponów"}
                        onChange={(event) => ctx.setNumberField('maxChances', event.target.value)}
                        type={"number"}
                        InputProps={{
                            inputProps: {step: 1}
                        }}
                    />
                    <TextField
                        fullWidth
                        value={ctx.event.settings.maxPrizePerDay}
                        helperText={"Maksymalna ilość nagród w ciągu dnia na jednego uczestnika"}
                        onChange={(event) => ctx.setNumberField('maxPrizePerDay', event.target.value)}
                        type={"number"}
                        InputProps={{
                            inputProps: {step: 1}
                        }}
                    />
                    <TextField
                        fullWidth
                        value={ctx.event.settings.maxPrizeTotal}
                        helperText={"Łączna maksymalna ilość nagród ma jednego uczestnika"}
                        onChange={(event) => ctx.setNumberField('maxPrizeTotal', event.target.value)}
                        type={"number"}
                        InputProps={{
                            inputProps: {step: 1}
                        }}
                    />
                </Grid>
            </Grid>
            {(ctx.event.settings.eventType === SMS_EVENT_TYPE || ctx.event.settings.eventType === SCANNED_COUPONS_EVENT_TYPE) &&
                <FormGroup>
                    <FormControlLabel control={<Switch checked={ctx.event.settings.extraChances}
                                                       onChange={() => ctx.setFieldRequired('extraChances')}/>}
                                      label="Dodatkowe szanse"/>
                </FormGroup>}
            {ctx.event.settings.extraChances ? <Grid item container justifyContent={'space-between'}>
                <Grid item container xs={5}>
                    <TextField
                        fullWidth
                        value={ctx.event.settings.maxExtraChancesPerRegistration}
                        helperText={"Maksymalna ilość dodatkowych szans podczas jednej rejestracji"}
                        onChange={(event) => ctx.setNumberField('maxExtraChancesPerRegistration', event.target.value)}
                        type={"number"}
                        InputProps={{
                            inputProps: {step: 1}
                        }}
                    />
                </Grid>
                <Grid item container xs={5}>
                    <TextField
                        fullWidth
                        value={ctx.event.settings.maxExtraChancesTotal}
                        helperText={"Maksymalna ilość dodatkowych szans podczas całej loterii"}
                        onChange={(event) => ctx.setNumberField('maxExtraChancesTotal', event.target.value)}
                        type={"number"}
                        InputProps={{
                            inputProps: {step: 1}
                        }}
                    />
                </Grid>
            </Grid> : <></>}
            {(ctx.event?.settings?.eventType === SMS_EVENT_TYPE ||
                ctx.event?.settings?.eventType === SCANNED_COUPONS_EVENT_TYPE) && <>
                <Typography variant={"button"} mt={2}>Ustawienia animacji</Typography>
                <TextField
                    sx={{minWidth: "300px"}}
                    value={ctx.event.settings.animationFrom}
                    helperText={"Od której sekundy ma się wyświetlać nazwa nagrody"}
                    onChange={(event) => ctx.setNumberField('animationFrom', event.target.value)}
                    type={"number"}
                />
                <TextField
                    sx={{minWidth: "300px"}}
                    value={ctx.event.settings.animationTo}
                    helperText={"Do której sekundy ma się wyświetlać nazwa nagrody"}
                    onChange={(event) => ctx.setNumberField('animationTo', event.target.value)}
                    type={"number"}
                />
                <MuiColorInput
                    sx={{".MuiColorInput-Popover": {backgroundColor: "white !important"}}}
                    format={'hex'}
                    helperText={'Kolor nazwy nagrody na animacji'}
                    value={ctx.event?.settings?.animationColor ? ctx.event?.settings?.animationColor : '#444'}
                    onChange={(value, color) => {

                        ctx.setTextField('animationColor', value)
                    }}/>
            </>}
            {(ctx.event?.settings?.eventType === SMS_EVENT_TYPE ||
                    (ctx.event?.settings?.eventType === INSTANT_WIN_EVENT_TYPE && ctx.event?.settings?.identifier === 'phone'))
                && <>
                    <Typography variant={"button"} mt={2}>Ustawienia sms</Typography>
                    <TextField
                        sx={{minWidth: "300px"}}
                        value={ctx.event.settings.smsPrefix}
                        helperText={"Początek wiadomości sms"}
                        onChange={(event) => ctx.setTextField('smsPrefix', event.target.value)}
                    />
                    <TextField
                        sx={{minWidth: "300px"}}
                        value={ctx.event.settings.smsSuffix}
                        helperText={"Koniec wiadomości sms"}
                        onChange={(event) => ctx.setTextField('smsSuffix', event.target.value)}
                    />
                    <FormControl>
                        <Select
                            value={ctx.event.settings.smsSender}
                            sx={{minWidth: "300px", minHeight: "40px"}}
                            onChange={(event) => ctx.setTextField('smsSender', event.target.value)}
                        >
                            <MenuItem value={'Loteria'}>Loteria</MenuItem>
                            <MenuItem value={'Promocja'}>Promocja</MenuItem>
                        </Select>
                        <FormHelperText>Nazwa nadawcy</FormHelperText>
                    </FormControl>
                </>}
            <Typography variant={"button"} mt={2}>Zgody marketingowe</Typography>
            <Grid item container>
                <IconButton onClick={ctx.addAgreement}><Icon>add</Icon></IconButton>
                <IconButton onClick={ctx.removeAgreement}><Icon>remove</Icon></IconButton>
            </Grid>
            {ctx.event?.settings?.agreements && ctx.event?.settings?.agreements.map((a: string, i: number) => {
                return <TextField
                    key={"Zgoda marketingowa " + (i + 1)}
                    fullWidth
                    value={a}
                    helperText={"Zgoda marketingowa " + (i + 1)}
                    onChange={(event) => ctx.setAgreement(i, event.target.value)}
                />
            })}
            <Button variant={"contained"} fullWidth size="large" sx={{color: "white !important", mt: 5}}
                    onClick={saveSettings}>Zapisz zmiany</Button>
        </>}
    </Grid>
}

export default EventSettingsPanel;
