import DashboardLayout from "../../layouts/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";

const HomePage = () => {
    return <DashboardLayout>
        <DashboardNavbar/>
        <h6>Brak wydarzeń</h6>
    </DashboardLayout>
}

export default HomePage;