import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/parts/MDBox";
import MDTypography from "components/parts/MDTypography";
import DashboardLayout from "layouts//DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "components/Tables/DataTable";
import participantsTableData from "pages/tables/data/participantsTableData";
import {useContext, useEffect, useState} from "react";
import {getAllParticipantsBySlug} from "../../api/participant-api";
import {useParams} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import AlertContext from "../../context/alert-context";
import AuthContext from "../../context/auth-context";

function Tables() {
    const {slug} = useParams();
    const {user} = useContext(AuthContext);
    const [data, setData] = useState({columns: [], rows: []});
    const [participants, setParticipants] = useState([]);
    const [page, setPage] = useState(1);
    const [searchNumber, setSearchNumber] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const alertContext = useContext(AlertContext);

    useEffect(() => {
        setPage(1);
    }, [slug]);

    useEffect(() => {
        if (searchNumber === "") {
            const data = participantsTableData(participants, slug);
            setData(data);
        } else {
            const filteredParticipants = participants.filter(p => p.phone && (p.phone.indexOf(searchNumber, 0) > -1));
            const data = participantsTableData(filteredParticipants, slug);
            setData(data);
        }
    }, [searchNumber])

    useEffect(() => {
        setIsLoading(true);
        getAllParticipantsBySlug(user?.token, slug, (participants) => {
            setParticipants(participants);
            const receivedData = participantsTableData(participants, slug);
            setData(receivedData);
            setIsLoading(false)
            // let newParticipants = [];
            // if(page === 1) {
            //     newParticipants = receivedParticipants;
            // } else {
            //     newParticipants = [...participants, ...receivedParticipants];
            // }
            // if (lastPage > page) {
            //     setPage(page + 1);
            // }
            // setParticipants(newParticipants);
            // const receivedData = participantsTableData(newParticipants, slug);
            // setData(receivedData);
        }, (error) => {
            alertContext.setAlert({title: error, color: "error"});
            setIsLoading(false);
        });
    }, [page, slug]);

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>

                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Uczestnicy
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} sx={{display: "flex", justifyContent: "center"}}>
                                {isLoading ?
                                    <CircularProgress color={"info"} sx={{my: 8}}/> :
                                    <DataTable
                                        table={data}
                                        isSorted={true}
                                        entriesPerPage={10}
                                        showTotalEntries={true}
                                        canSearch={true}
                                        searchLabel={"Szukaj po numerze"}
                                        onSearch={(value) => setSearchNumber(value.toString())}
                                        noEndBorder
                                    />
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default Tables;
