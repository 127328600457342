import Grid from "@mui/material/Grid";
import MDBox from "components/parts/MDBox";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "components/Cards/StatisticsCards/ComplexStatisticsCard";
import ShopsData from "pages/dashboard/components/ShopsData";
import {useContext, useEffect, useState} from "react";
import AlertContext from "context/alert-context";
import DailyStats from "pages/dashboard/components/DailyStats";
import {getStatistics} from "../../api/statistics-api";
import {useParams} from "react-router-dom";
import StatisticsContext from "../../context/statistics-context";
import {Statistic} from "../../model/Statistic";
import PrizesStats from "./components/PrizesStats";
import AuthContext from "../../context/auth-context";

function Dashboard() {
    const {statistics, setStatistics} = useContext(StatisticsContext);
    const {user} = useContext(AuthContext);
    const alertCtx = useContext(AlertContext);
    const {slug} = useParams();

    useEffect(() => {
        if (slug !== "login") {
            getStatistics(user.token, slug, statistic => {setStatistics(statistic)}, error =>
                alertCtx.setAlert({title: "Coś poszło nie tak.. spróbuj odświeżyć za chwilę"}))
        }
    }, [slug])

    const getGeneralAverage = () => {
        if (statistics.dailyStatistics && statistics.dailyStatistics.length > 0) {
            const generalAverage = statistics.dailyStatistics.map(stat => stat.receiptsAvg).reduce((prev, curr) => Number(prev) + Number(curr)) / statistics.dailyStatistics.length;
            return Math.round((generalAverage + Number.EPSILON) * 100) / 100;
        }
        return 0;
    }

    const getTotalValue = () => {
        if (statistics.dailyStatistics && statistics.dailyStatistics.length > 0) {
            const value = statistics.dailyStatistics.map(stat => stat.receiptsSum).reduce((prev, curr) => Number(prev) + Number(curr));
            return Math.round((Number(value) + Number.EPSILON) * 100) / 100;
        }
        return 0;
    }

    const getParticipantsNumber = () => {
        if (statistics.dailyStatistics && statistics.dailyStatistics.length > 0) {
            return statistics.dailyStatistics.map(stat => stat.participantsNumber).reduce((prev, curr) => prev + curr);
        }
        return 0;
    }

    const getReceiptsNumber = () => {
        if (statistics.dailyStatistics && statistics.dailyStatistics.length > 0) {
            return statistics.dailyStatistics.map(stat => stat.receiptsNumber).reduce((prev, curr) => prev + curr);
        }
        return 0;
    }

    const getPrizeNumber = () => {
        if (statistics.dailyStatistics && statistics.dailyStatistics.length > 0) {
            return statistics.dailyStatistics.map(stat => stat.won).reduce((prev, curr) => prev + curr);
        }
        return 0;
    }

    const getCollectedPrizeNumber = () => {
        if (statistics.dailyStatistics && statistics.dailyStatistics.length > 0) {
            return statistics.dailyStatistics.map(stat => stat.collected).reduce((prev, curr) => prev + curr);
        }
        return 0;
    }

    const getBasket = () => {
        if (statistics.dailyStatistics && statistics.dailyStatistics.length > 0) {
            const value = getTotalValue() / getParticipantsNumber();
            return `${Math.round((value + Number.EPSILON) * 100) / 100} zł`;
        }
        return 0;
    }

    const getRegistrationNumber = () => {
        if (statistics.dailyStatistics && statistics.dailyStatistics.length > 0) {
            return statistics.dailyStatistics.map(stat => stat.couponsNumber).reduce((prev, curr) => prev + curr);
        }
        return 0;
    }

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox py={3}>
                <Grid container spacing={3} alignItems={"stretch"}>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="dark"
                                icon="receipt_long"
                                title="Paragony"
                                count={getReceiptsNumber()}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                icon="person"
                                title="Uczestnicy"
                                count={getParticipantsNumber()}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="secondary"
                                icon="group_add"
                                title={"Ilość rejestracji"}
                                count={getRegistrationNumber()}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="error"
                                icon="local_mall"
                                title={"Koszyk"}
                                count={getBasket()}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="success"
                                icon="attach_money"
                                title="Wartość paragonów"
                                count={getTotalValue() + " zł"}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="primary"
                                icon="paid"
                                title="Średnia wartość paragonów"
                                count={getGeneralAverage() + " zł"}
                            />
                        </MDBox>
                    </Grid>
                    {/*<Grid item xs={12} md={6} lg={3}>*/}
                    {/*    <MDBox mb={1.5}>*/}
                    {/*        <ComplexStatisticsCard*/}
                    {/*            color="warning"*/}
                    {/*            icon="receipt"*/}
                    {/*            title="Ilość paragonów na klienta"*/}
                    {/*            count={statistics.averageReceiptsNumber}*/}
                    {/*        />*/}
                    {/*    </MDBox>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="warning"
                                icon="emoji_events"
                                title="Wygrane"
                                count={getPrizeNumber()}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                icon="emoji_events"
                                title="Odebrane nagrody"
                                count={getCollectedPrizeNumber()}
                            />
                        </MDBox>
                    </Grid>
                </Grid>
                <MDBox mt={5}>
                    <DailyStats/>
                <MDBox mt={3}>
                    <ShopsData shopsData={statistics.shopsData}/>
                </MDBox>
                </MDBox>
                <MDBox mt={5}>
                    <PrizesStats/>
                </MDBox>
            </MDBox>
        </DashboardLayout>
    );
}

export default Dashboard;
