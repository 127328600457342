import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/parts/MDBox";
import MDTypography from "components/parts/MDTypography";
import DashboardLayout from "layouts//DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "components/Tables/DataTable";
import prizesTableData from "pages/prizes/data/prizesTableData";
import {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {CSVReader} from "react-papaparse";
import DownloadIcon from '@mui/icons-material/Download';

import {
    Box,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    FormGroup, List, ListItemButton, ListItemText,
    TextField
} from "@mui/material";
import AlertContext from "context/alert-context";
import {deletePrize, getAllPrizes, editPrizeName, deleteAllPrizes, uploadPrizes, getReports} from "api/prize-api";
import MDButton from "components/parts/MDButton";
import AuthContext from "../../context/auth-context";

const EditModalContent = ({editPrize, onAction, closeModal}) => {
    const [editedPrize, setEditedPrize] = useState(editPrize);

    return (
        <>
            {
                editPrize && (
                    <>
                        <DialogTitle>
                            Edycja nagrody ID: {editedPrize && editedPrize.refNumber}
                        </DialogTitle>
                        <DialogContent>
                            <TextField disabled label="Wygrana" sx={{mt: 4}}
                                       value={editedPrize && (editedPrize.wonDate ? editedPrize.wonDate.toLocaleString() : "Nie wygrana")}/>
                            <TextField disabled label="Odebrana" sx={{mt: 4, ml: 4}}
                                       value={editedPrize && (editedPrize.collectedDate ? editedPrize.collectedDate.toLocaleString() : "Nie odebrana")}/>
                            <TextField fullWidth label="Nazwa" sx={{mt: 4}} value={editedPrize && editedPrize.name}
                                       onChange={(event) => {
                                           const changedPrize = {...editedPrize}
                                           changedPrize.name = event.target.value
                                           setEditedPrize(changedPrize)
                                       }}/>
                        </DialogContent>
                        <DialogActions>
                            <MDButton color={"success"} variant={"contained"} onClick={() => onAction(editedPrize)}>
                                Zapisz
                            </MDButton>
                            <MDButton variant={"outlined"} color={"secondary"} onClick={closeModal}>
                                Zamknij
                            </MDButton>
                        </DialogActions>
                    </>
                )}
        </>)
}

const DeleteModalContent = ({prizeToDelete, onAction, closeModal}) => {
    return (<>{prizeToDelete && (<>
        <DialogTitle>
            Czy napewno chcesz usunąć
            nagrodę {`${prizeToDelete && prizeToDelete.refNumber}:${prizeToDelete && prizeToDelete.name}`} ?
        </DialogTitle>
        <DialogActions>
            <MDButton color={"error"} variant={"contained"} onClick={onAction}>
                Usuń
            </MDButton>
            <MDButton variant={"outlined"} color={"secondary"} onClick={closeModal}>
                Zamknij
            </MDButton>
        </DialogActions>
    </>)}</>)
}

const DeleteAllModalContent = ({onAction, closeModal}) => {
    return (
        <>
            <DialogTitle>
                Czy napewno chcesz usunąć
                wszystkie nagrody ?
            </DialogTitle>
            <DialogActions>
                <MDButton color={"error"} variant={"contained"} onClick={onAction}>
                    Usuń
                </MDButton>
                <MDButton variant={"outlined"} color={"secondary"} onClick={closeModal}>
                    Zamknij
                </MDButton>
            </DialogActions>
        </>
    )
}

const PrizeImport = ({handleImport}) => {
    const {slug} = useParams();
    const alertContext = useContext(AlertContext);
    const {user} = useContext(AuthContext);

    const handleOnDrop = (data, file) => {
        let prizes = []
        try {
            prizes = data.filter(d => {
                return d.data.length >= 3 && d.data[1] !== '' && d.data[2] !== '';
            }).map(d => {
                const date = d.data[1].replace(/[/. ]/g, '-')
                return {name: d.data[0], winningDate: `${date} ${d.data[2]}`, refNumber: d.data[3]}
            })
        } catch (error) {
            console.log(error)
            alertContext.setAlert({title: 'Błąd przy wczytywaniu pliku. Sprawdź format danych', color: "error"})
            return;
        }
        const regex = new RegExp('^\\d{2,4}-\\d{2}-\\d{2,4} \\d{2}:\\d{2}:\\d{2}')
        const wrongPrizes = prizes.filter(p => !p.name || !regex.test(p.winningDate))
        if (wrongPrizes.length > 0) {
            console.log("Błąd przy parsowaniu pliku")
            alertContext.setAlert({title: 'Dane są niepoprawnie sformatowane.', color: "error"})
            return;
        }
        uploadPrizes(user?.token, slug, prizes,
            (prizes) => handleImport(prizes),
            (error) => alertContext.setAlert({title: error, color: "error"}))
    };

    const handleOnError = (err, file, inputElem, reason) => {
        alertContext.setAlert({title: 'Błąd czytnika csv. Sprawdź format danych', color: "error"})
    };

    const handleOnRemoveFile = (data, file) => {
    };

    return (
        <Box sx={{width: "60%", display: "flex"}}>
            <CSVReader
                onDrop={handleOnDrop}
                onError={handleOnError}
                addRemoveButton
                removeButtonColor={"red"}
                onRemoveFile={handleOnRemoveFile}
                config={{
                    encoding: "UTF-8"
                }}
            >
                <span>Import nagród</span>
            </CSVReader>
            <List sx={{maxWidth: "50%"}}>
                <ListItemText sx={{ml: 3}} primaryTypographyProps={{variant: "caption"}}>Plik w formacie
                    .csv</ListItemText>
                <ListItemText sx={{ml: 3}} primaryTypographyProps={{variant: "caption"}}>Cztery kolumny (bez nagłówka,
                    same dane): nazwa nagrody, data wygrania (np. 02-12-2022), czas wygrania (np. 12:05:30), id nagrody (np.: 425) </ListItemText>
                <ListItemButton sx={{ml: 1}} component="a" href="/nagrody-przyklad.csv">
                    <ListItemText primary="Przykładowy plik"
                                  primaryTypographyProps={{variant: "caption", color: "blue"}}/>
                </ListItemButton>
            </List>
        </Box>
    )
}

const Prizes = () => {
    const {slug} = useParams();
    const [data, setData] = useState({columns: [], rows: []});
    const [prizes, setPrizes] = useState([]);
    const [page, setPage] = useState(1);
    const [searchPhrase, setSearchPhrase] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [prizeToDelete, setPrizeToDelete] = useState();
    const [editPrize, setEditPrize] = useState();
    const [showNotCollected, setShowNotCollected] = useState(false);
    const [showNotWon, setShowNotWon] = useState(false);
    const [deleteAll, setDeleteAll] = useState(false);
    const alertContext = useContext(AlertContext);
    const {user} = useContext(AuthContext);


    const openDeleteModal = (prize) => {
        setPrizeToDelete(prize);
    }

    const openEditModal = (prize) => {
        setEditPrize(prize);
    }

    const closeModal = () => {
        setPrizeToDelete(null);
        setEditPrize(null);
        setDeleteAll(false);
    }

    const handleImport = (prizes) => {
        setPrizes(prizes);
        const receivedData = getTableData(prizes);
        setData(receivedData);
    }

    const getTableData = (prizes) => prizesTableData(prizes, slug, openEditModal, openDeleteModal);

    useEffect(() => {
        setPage(1);
    }, [slug]);

    useEffect(() => {
        let filteredPrizes = [...prizes]
        if (showNotCollected) {
            filteredPrizes = filteredPrizes.filter(p => !p.collectedDate)
        }
        if (showNotWon) {
            filteredPrizes = filteredPrizes.filter(p => !p.wonDate)
        }
        if (searchPhrase !== "") {
            filteredPrizes = filteredPrizes.filter(p => p.name.indexOf(searchPhrase, 0) > -1);
        }
        const data = getTableData(filteredPrizes);
        setData(data);
    }, [searchPhrase, showNotWon, showNotCollected])

    useEffect(() => {
        setIsLoading(true);
        getAllPrizes(user?.token, slug, (prizes) => {
            setPrizes(prizes);
            const receivedData = getTableData(prizes);
            setData(receivedData);
            setIsLoading(false)
        }, (error) => {
            alertContext.setAlert({title: error, color: "error"});
            setIsLoading(false);
        });
    }, [page, slug]);

    const downloadReports = () => {
        getReports(user?.token, slug, (error) => {
            alertContext.setAlert({title: error, color: "error"});
            setIsLoading(false);
        });
    }

    return (<DashboardLayout>
            <DashboardNavbar/>
            <MDBox sx={{display: "flex", justifyContent: "space-between", alignItems: "start", width: "100%", p: 3}}>
                <PrizeImport handleImport={handleImport}/>
                <MDButton color={"error"} onClick={() => setDeleteAll(true)}>
                    Usuń wszystkie
                </MDButton>
            </MDBox>
            <MDBox sx={{display: "flex", flexDirection: "column", alignItems: "start", width: "100%", p: 3}}>
                <MDTypography variant="h6" mb={3}>
                    Protokoły odbioru nagrody
                </MDTypography>
                <MDButton color={"info"} startIcon={<DownloadIcon/>}  onClick={downloadReports}
                          // target={"_blank"}
                          // href={process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/api/events/${slug}/reports` : ''}
                >
                    Pobierz wszystkie
                </MDButton>
            </MDBox>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Nagrody
                                </MDTypography>
                            </MDBox>
                            <MDBox sx={{display: "flex", p: 3}}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={showNotCollected}
                                                                         onChange={() => setShowNotCollected(!showNotCollected)}/>}
                                                      label="Tylko nieodebrane"/>
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={showNotWon}
                                                                         onChange={() => setShowNotWon(!showNotWon)}/>}
                                                      label="Tylko niewygrane"/>
                                </FormGroup>
                            </MDBox>
                            <MDBox pt={3} sx={{display: "flex", justifyContent: "center"}}>
                                {isLoading ? <CircularProgress color={"info"} sx={{my: 8}}/> : <DataTable
                                    table={data}
                                    isSorted={true}
                                    entriesPerPage={10}
                                    showTotalEntries={true}
                                    canSearch={true}
                                    searchLabel={"Szukaj po nazwie"}
                                    onSearch={(value) => setSearchPhrase(value.toString())}
                                    noEndBorder
                                />}
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Dialog open={prizeToDelete || editPrize || deleteAll} onClose={closeModal}>
                <DeleteModalContent prizeToDelete={prizeToDelete} onAction={() => {
                    if (prizeToDelete) {
                        deletePrize(user?.token, slug, prizeToDelete.id, () => {
                            const newPrizes = prizes.filter(p => p.id !== prizeToDelete.id);
                            const data = getTableData(newPrizes);
                            setPrizes(newPrizes);
                            setData(data);
                            closeModal();
                            alertContext.setAlert({title: 'Nagroda została usunięta', color: "success"})
                        }, (error) => alertContext.setAlert({title: error, color: "error"}))
                    }
                }
                } closeModal={closeModal}/>
                <EditModalContent editPrize={editPrize} onAction={(editedPrize) => {
                    if (editedPrize) {
                        editPrizeName(user?.token, slug, editedPrize.id, editedPrize.name, (prize) => {
                            const newPrizes = prizes.filter(p => p.id !== editedPrize.id);
                            newPrizes.push(prize)
                            const data = getTableData(newPrizes);
                            setPrizes(newPrizes);
                            setData(data);
                            closeModal();
                            alertContext.setAlert({title: 'Nagroda została zaktualizowana', color: "success"})
                        }, (error) => alertContext.setAlert({title: error, color: "error"}))
                    }
                }} closeModal={closeModal}/>
                {deleteAll && <DeleteAllModalContent closeModal={closeModal} onAction={() => {
                    deleteAllPrizes(user?.token, slug, (msg) => {
                        setPrizes([]);
                        const data = getTableData([]);
                        setData(data);
                        alertContext.setAlert({title: `Usunięto: ${msg} nagrody`, color: "success"})
                    }, (error) => alertContext.setAlert({title: error, color: "error"}))
                }}/>}
            </Dialog>
            <Footer/>
        </DashboardLayout>
    );
}

export default Prizes;
